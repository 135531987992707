import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Edit, Create } from "@pankod/refine-antd";
import { IRegion } from 'interfaces';

export const RegionCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IRegion>();

  return (
    <Create isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('regions.fields.denomination')} name='denomination' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
