import { useTranslate, useMany } from "@pankod/refine-core";
import { useForm, Form, Input, Edit, useSelect, Select, InputNumber } from "@pankod/refine-antd";
import { IAnswer, IQuestion, IReview } from 'interfaces';

export const AnswerEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IAnswer>();

  const { selectProps: questionSelectProps } = useSelect<IQuestion>({
    resource: 'questions',
    defaultValue: queryResult?.data?.data?.question_id,
    optionLabel: 'content',
    optionValue: 'id',
  });

  const { selectProps: reviewSelectProps } = useSelect<IReview>({
    resource: 'reviews',
    defaultValue: queryResult?.data?.data?.review_id,
    optionLabel: 'id',
    optionValue: 'id',
  });

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('answers.fields.rating')} name='rating' rules={[{ required: true }]}>
          <InputNumber step={1} min={1} max={5} />
        </Form.Item>

        <Form.Item label={translate('answers.fields.question.title')} name='question_id' rules={[{ required: true }]}>
          <Select {...questionSelectProps} />
        </Form.Item>

        <Form.Item label={translate('answers.fields.review.title')} name='review_id' rules={[{ required: true }]}>
          <Select {...reviewSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
