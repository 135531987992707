import React from 'react';
import { useMany, useTranslate, useGetLocale, useDeleteMany } from '@pankod/refine-core';

import {
  Input,
  useTable,
  List,
  Table,
  DateField,
  Select,
  useSelect,
  TextField,
  FilterDropdown,
  ShowButton,
  Space,
  EditButton,
  DeleteButton,
  Radio,
  getDefaultSortOrder,
  BooleanField,
  Button,
  CreateButton,
  useModal,
  Modal,
  Checkbox,
  Typography,
  Icons,
  getDefaultFilter,
  Tag,
} from '@pankod/refine-antd';

import { IDoctor, IDoctorRole, IHospital } from 'interfaces';

export const DoctorList = () => {
  const translate = useTranslate();
  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate('referring_doctors.fields.id'),
        value: 'id',
      },
      {
        label: translate('referring_doctors.fields.first_name'),
        value: 'first_name',
      },
      {
        label: translate('referring_doctors.fields.last_name'),
        value: 'last_name',
      },
      {
        label: translate('referring_doctors.fields.field'),
        value: 'field',
      },
      {
        label: translate('referring_doctors.fields.role.title'),
        value: 'role_id',
      },
      {
        label: translate('referring_doctors.fields.interview_url'),
        value: 'interview_url',
      },
      {
        label: translate('referring_doctors.fields.causes_deprecation'),
        value: 'causes_deprecation',
      },
      {
        label: translate('referring_doctors.fields.hospital.title'),
        value: 'hospital_id',
      },
      {
        label: translate('referring_doctors.fields.created_at'),
        value: 'created_at',
      },
      {
        label: translate('referring_doctors.fields.updated_at'),
        value: 'updated_at',
      },
    ],
    []
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([
    'created_at',
    'updated_at',
    'id',
    'interview_url',
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { tableProps, filters, sorter, tableQueryResult } = useTable<IDoctor>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc',
      },
      {
        field: 'hospital_id',
        order: 'asc',
      },
    ],
    initialFilter: [
      {
        field: 'last_name',
        operator: 'contains',
        value: undefined,
      },
      {
        field: 'field',
        operator: 'contains',
        value: undefined,
      },
    ],
  });
  const hospitalIds = tableProps?.dataSource?.map((item) => item.hospital_id) ?? [];
  const { data: hospitalData, isLoading } = useMany<IHospital>({
    resource: 'hospitals',
    ids: hospitalIds,
    queryOptions: {
      enabled: hospitalIds.length > 0,
    },
  });
  const roleIds = tableProps?.dataSource?.map((item) => item.role_id).filter((item) => typeof item === 'number') ?? [];

  const { data: roleData, isLoading: roleIsLoading } = useMany<IDoctorRole>({
    resource: 'referring_doctors_roles',
    ids: roleIds as string[],
    queryOptions: {
      enabled: roleIds.length > 0,
    },
  });
  const { selectProps: hospitalSelectProps } = useSelect<IHospital>({
    resource: 'hospitals',
    optionLabel: 'denomination',
    optionValue: 'id',
  });

  const { selectProps: roleSelectProps } = useSelect<IDoctorRole>({
    resource: 'referring_doctors_roles',
    optionLabel: 'role_name',
    optionValue: 'id',
  });
  const { mutate, isSuccess, isLoading: deleteManyIsLoading } = useDeleteMany<IDoctor>();
  const deleteSelectedItems = () => {
    mutate({
      resource: 'referring_doctors',
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked) setHiddenColumns((prev) => prev.filter((col) => col !== value));
    else setHiddenColumns((prev) => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column
        key='id'
        dataIndex='id'
        title={translate('referring_doctors.fields.id')}
        sorter
        defaultSortOrder={getDefaultSortOrder('id', sorter)}
      />,
      <Table.Column key='first_name' dataIndex='first_name' title={translate('referring_doctors.fields.first_name')} />,
      <Table.Column
        key='last_name'
        dataIndex='last_name'
        title={translate('referring_doctors.fields.last_name')}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder={translate('labels.search')} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter('last_name', filters)}
      />,
      <Table.Column
        key='field'
        dataIndex='field'
        title={translate('referring_doctors.fields.field')}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder={translate('labels.search')} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter('field', filters)}
      />,
      <Table.Column
        key='role_id'
        dataIndex='role_id'
        title={translate('referring_doctors.fields.role.title')}
        render={(value: string) => {
          if (roleIsLoading) {
            return <TextField value={translate('loading')} />;
          }
          return <TextField value={roleData?.data.find((item: IDoctorRole) => item.id === value)?.role_name} />;
        }}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              mode='multiple'
              style={{ minWidth: 200 }}
              placeholder={translate('referring_doctors.fields.role.filters.placeholder')}
              {...roleSelectProps}
            />
          </FilterDropdown>
        )}
        defaultSortOrder={getDefaultSortOrder('role_id', sorter)}
      />,
      <Table.Column
        key='interview_url'
        dataIndex='interview_url'
        title={translate('referring_doctors.fields.interview_url')}
      />,
      <Table.Column
        key='hospital_id'
        dataIndex='hospital_id'
        title={translate('referring_doctors.fields.hospital.title')}
        render={(value: string) => {
          if (isLoading) {
            return <TextField value={translate('loading')} />;
          }
          return <TextField value={hospitalData?.data.find((item: IHospital) => item.id === value)?.denomination} />;
        }}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              mode='multiple'
              style={{ minWidth: 200 }}
              placeholder={translate('referring_doctors.fields.hospital.filters.placeholder')}
              {...hospitalSelectProps}
            />
          </FilterDropdown>
        )}
        defaultSortOrder={getDefaultSortOrder('hospital_id', sorter)}
      />,
      <Table.Column
        key='causes_deprecation'
        dataIndex='causes_deprecation'
        title={translate('referring_doctors.fields.causes_deprecation')}
        render={(value) => (
          <BooleanField
            color={value ? '#f5222d' : '#52c41a'}
            value={value}
            trueIcon={<Icons.CheckCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#f5222d' />}
            falseIcon={<Icons.CloseCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#52c41a' />}
            valueLabelFalse={translate('no')}
            valueLabelTrue={translate('yes')}
          />
        )}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value='true'>{translate('yes')}</Radio>
              <Radio value='false'>{translate('no')}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key='created_at'
        dataIndex='created_at'
        title={translate('referring_doctors.fields.created_at')}
        render={(value) => <DateField format='lll' value={value} />}
        sorter
      />,

      <Table.Column
        key='updated_at'
        dataIndex='updated_at'
        title={translate('referring_doctors.fields.updated_at')}
        render={(value) => <DateField format='lll' value={value} />}
        sorter
      />,
    ],
    [tableProps, sorter, hospitalSelectProps, isLoading, hospitalData, filters]
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter((column) => !hiddenColumns.includes(column.props.dataIndex));
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button danger onClick={deleteSelectedItems} disabled={!hasSelected} loading={deleteManyIsLoading}>
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color='#f1c531'>
                {translate('labels.totalNumber', {
                  resource: translate('referring_doctors.referring_doctors').toLowerCase(),
                })}
                {tableQueryResult.data?.total || ''}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>{translate('table.hideColumns.buttonTitle')}</Button>
            </Space>
          ),
        }}
      >
        <Table<IDoctor>
          rowSelection={rowSelection}
          {...tableProps}
          size='small'
          scroll={{ y: '60vh', x: 10 * visibleColumns.length + 'vw' }}
          pagination={{ ...tableProps.pagination, position: ['topLeft'], size: 'small' }}
          rowKey='id'
        >
          <Table.Column<IDoctor>
            title=''
            dataIndex='actions'
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton title={translate('buttons.show')} size='small' recordItemId={record.id} hideText />
                  <EditButton title={translate('buttons.edit')} size='small' recordItemId={record.id} hideText />
                  <DeleteButton title={translate('buttons.delete')} size='small' recordItemId={record.id} hideText />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction='vertical'>
          <Typography.Text>{translate('table.hideColumns.title')}</Typography.Text>
          {defaultColumns.map((column) => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={(e) => handleHideColumn(column.value, e.target.checked)}
            >
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
