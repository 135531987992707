import React from "react";
import { useTranslate, useDeleteMany, useMany } from "@pankod/refine-core";

import {
  useTable,
  List,
  Table,
  DateField,
  useModal,
  Button,
  Space,
  CreateButton,
  ShowButton,
  DeleteButton,
  Modal,
  Typography,
  Checkbox,
  FilterDropdown,
  getDefaultSortOrder,
  useSelect,
  TextField,
  Select,
  Tag,
} from "@pankod/refine-antd";

import {
  IHospital,
  IHospitalStudy,
  IExperimentalStudy,
  IDoctor,
} from "interfaces";

export const HospitalStudyList = () => {
  const translate = useTranslate();
  const { tableProps, sorter, tableQueryResult } = useTable<IHospitalStudy>({
    initialSorter: [
      {
        field: "hospital_id",
        order: "asc",
      },
    ],
  });

  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate("hospital_study.fields.id"),
        value: "id",
      },
      {
        label: translate("hospital_study.fields.hospital.title"),
        value: "hospital_id",
      },
      {
        label: translate("hospital_study.fields.study.title"),
        value: "study_id",
      },
      {
        label: translate("hospital_study.fields.doctor.title"),
        value: "doctor_id",
      },
      {
        label: translate("hospital_study.fields.created_at"),
        value: "created_at",
      },
      {
        label: translate("hospital_study.fields.updated_at"),
        value: "updated_at",
      },
    ],
    [],
  );

  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const hospitalIds =
    tableProps?.dataSource?.map((item) => item.hospital_id) ?? [];
  const { data: hospitalData, isLoading } = useMany<IHospital>({
    resource: "hospitals",
    ids: hospitalIds,
    queryOptions: {
      enabled: hospitalIds.length > 0,
    },
  });
  const { selectProps: hospitalSelectProps } = useSelect<IHospital>({
    resource: "hospitals",
    optionLabel: "denomination",
    optionValue: "id",
  });

  const studyIds = tableProps?.dataSource?.map((item) => item.study_id) ?? [];
  const { data: studyData, isLoading: studyIsLoading } =
    useMany<IExperimentalStudy>({
      resource: "experimental_studies",
      ids: studyIds,
      queryOptions: {
        enabled: studyIds.length > 0,
      },
    });
  const { selectProps: studySelectProps } = useSelect<IExperimentalStudy>({
    resource: "experimental_studies",
    optionLabel: "name",
    optionValue: "id",
  });

  const doctorIds =
    tableProps?.dataSource
      ?.filter((item) => !!item.doctor_id)
      .map((item) => item.doctor_id) ?? [];
  const { data: doctorData, isLoading: doctorIsLoading } = useMany<IDoctor>({
    resource: "referring_doctors",
    ids: doctorIds,
    queryOptions: {
      enabled: doctorIds.length > 0,
    },
  });
  const { selectProps: doctorSelectProps } = useSelect<IDoctor>({
    resource: "referring_doctors",
    optionLabel: "last_name",
    optionValue: "id",
  });

  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([
    "created_at",
    "updated_at",
    "id",
  ]);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const {
    mutate,
    isSuccess,
    isLoading: deleteManyIsLoading,
  } = useDeleteMany<IHospitalStudy>();
  const deleteSelectedItems = () => {
    mutate({
      resource: "hospital_study",
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked)
      setHiddenColumns((prev) => prev.filter((col) => col !== value));
    else setHiddenColumns((prev) => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column<IHospitalStudy>
        key="id"
        dataIndex="id"
        title={translate("hospital_study.fields.id")}
        sorter
        defaultSortOrder={getDefaultSortOrder("id", sorter)}
      />,
      <Table.Column<IHospitalStudy>
        key="hospital_id"
        dataIndex="hospital_id"
        title={translate("hospital_study.fields.hospital.title")}
        render={(value: string) => {
          if (isLoading) {
            return <TextField value={translate("loading")} />;
          }
          return (
            <TextField
              value={
                hospitalData?.data.find((item: IHospital) => item.id === value)
                  ?.denomination
              }
            />
          );
        }}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              mode="multiple"
              style={{ minWidth: 200 }}
              placeholder={translate(
                "hospital_study.fields.hospital.filters.placeholder",
              )}
              {...hospitalSelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column<IHospitalStudy>
        key="study_id"
        dataIndex="study_id"
        title={translate("hospital_study.fields.study.title")}
        render={(value: string) => {
          if (studyIsLoading) {
            return <TextField value={translate("loading")} />;
          }
          return (
            <TextField
              value={studyData?.data.find((item) => item.id === value)?.name}
            />
          );
        }}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              mode="multiple"
              style={{ minWidth: 200 }}
              placeholder={translate(
                "hospital_study.fields.study.filters.placeholder",
              )}
              {...studySelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column<IHospitalStudy>
        key="doctor_id"
        dataIndex="doctor_id"
        title={translate("hospital_study.fields.doctor.title")}
        render={(value: string) => {
          if (doctorIsLoading) {
            return <TextField value={translate("loading")} />;
          }
          return (
            <TextField
              value={
                doctorData?.data.find((item) => item.id === value)?.last_name
              }
            />
          );
        }}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              mode="multiple"
              style={{ minWidth: 200 }}
              placeholder={translate(
                "hospital_study.fields.doctor.filters.placeholder",
              )}
              {...studySelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column<IHospitalStudy>
        dataIndex="created_at"
        title={translate("hospital_study.fields.created_at")}
        render={(value) => <DateField format="LLL" value={value} />}
        sorter
      />,
      <Table.Column<IHospitalStudy>
        dataIndex="updated_at"
        title={translate("hospital_study.fields.updated_at")}
        render={(value) => <DateField format="LLL" value={value} />}
        sorter
      />,
    ],
    [
      tableProps,
      sorter,
      hospitalData,
      hospitalSelectProps,
      studyData,
      studySelectProps,
      studyIsLoading,
      isLoading,
    ],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(
      (column) => !hiddenColumns.includes(column.props.dataIndex),
    );
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button
              danger
              onClick={deleteSelectedItems}
              disabled={!hasSelected}
              loading={deleteManyIsLoading}
            >
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color="#f1c531">
                {translate("labels.totalNumber", {
                  resource: translate(
                    "hospital_study.hospital_study",
                  ).toLowerCase(),
                })}
                {tableQueryResult.data?.total || ""}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>
                {translate("table.hideColumns.buttonTitle")}
              </Button>
            </Space>
          ),
        }}
      >
        <Table<IHospitalStudy>
          rowSelection={rowSelection}
          {...tableProps}
          size="small"
          scroll={{ y: "60vh", x: 10 * visibleColumns.length + "vw" }}
          pagination={{
            ...tableProps.pagination,
            position: ["topLeft"],
            size: "small",
          }}
          rowKey={"id"}
        >
          <Table.Column<IHospitalStudy>
            title=""
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton
                    title={translate("buttons.show")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <DeleteButton
                    title={translate("buttons.delete")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction="vertical">
          <Typography.Text>
            {translate("table.hideColumns.title")}
          </Typography.Text>
          {defaultColumns.map((column) => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={(e) => handleHideColumn(column.value, e.target.checked)}
            >
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
