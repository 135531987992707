import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, DateField } from "@pankod/refine-antd";
import {
  IHospitalStudy,
  IHospital,
  IExperimentalStudy,
  IDoctor,
} from "interfaces";

const { Title, Text } = Typography;

export const HospitalStudyShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IHospitalStudy>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: hospitalData } = useOne<IHospital>({
    resource: "hospitals",
    id: record?.hospital_id || "",
    queryOptions: {
      enabled: !!record?.hospital_id,
    },
  });

  const { data: studyData } = useOne<IExperimentalStudy>({
    resource: "experimental_studies",
    id: record?.study_id || "",
    queryOptions: {
      enabled: !!record?.study_id,
    },
  });

  const { data: doctorData } = useOne<IDoctor>({
    resource: "referring_doctors",
    id: record?.doctor_id || "",
    queryOptions: {
      enabled: !!record?.doctor_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate("hospital_study.fields.id")}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>
        {translate("hospital_study.fields.hospital.title")}
      </Title>
      <Text>{hospitalData?.data?.denomination}</Text>

      <Title level={5}>{translate("hospital_study.fields.study.title")}</Title>
      <Text>{studyData?.data.name}</Text>

      <Title level={5}>{translate("hospital_study.fields.doctor.title")}</Title>
      <Text>
        {doctorData?.data.first_name} - {doctorData?.data.last_name}
      </Text>

      <Title level={5}>{translate("hospital_study.fields.created_at")}</Title>
      <DateField format="lll" value={record?.created_at} />

      <Title level={5}>{translate("hospital_study.fields.updated_at")}</Title>
      <DateField format="lll" value={record?.updated_at} />
    </Show>
  );
};
