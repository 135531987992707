import { useShow, useTranslate } from '@pankod/refine-core';
import { Show, Typography, DateField } from '@pankod/refine-antd';
import { IDoctorRole } from 'interfaces';

const { Title, Text } = Typography;

export const DoctorRoleShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IDoctorRole>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('referring_doctors_roles.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('referring_doctors_roles.fields.role_name')}</Title>
      <Text>{record?.role_name || translate('notAvailable')}</Text>

      <Title level={5}>{translate('referring_doctors_roles.fields.sorting_order')}</Title>
      <Text>{record?.sorting_order || translate('notAvailable')}</Text>

      <Title style={{ marginTop: 16 }} level={5}>
        {translate('referring_doctors_roles.fields.created_at')}
      </Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('referring_doctors_roles.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
