import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField } from "@pankod/refine-antd";
import { IProvince, IRegion } from 'interfaces';

const { Title, Text } = Typography;

export const ProvinceShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IProvince>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: regionData } = useOne<IRegion>({
    resource: 'regions',
    id: record?.region_id || '',
    queryOptions: {
      enabled: !!record?.region_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('provinces.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('provinces.fields.denomination')}</Title>
      <Text>{record?.denomination}</Text>

      <Title level={5}>{translate('provinces.fields.abbreviation')}</Title>
      <Text>{record?.abbreviation}</Text>

      <Title level={5}>{translate('provinces.fields.region.title')}</Title>
      <Text>{regionData?.data.denomination}</Text>

      <Title level={5}>{translate('provinces.fields.created_at')}</Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('provinces.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
