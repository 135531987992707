import React from 'react';
import { useTranslate, useDeleteMany, useMany } from "@pankod/refine-core";

import {
  useTable,
  List,
  Table,
  DateField,
  useModal,
  Button,
  Space,
  CreateButton,
  ShowButton,
  EditButton,
  DeleteButton,
  Modal,
  Typography,
  Checkbox,
  FilterDropdown,
  getDefaultFilter,
  Input,
  getDefaultSortOrder,
  useSelect,
  TextField,
  Select,
  NumberField,
  UrlField,
  Tag,
} from "@pankod/refine-antd";

import { IHospital, IHospitalTrait, ITrait } from 'interfaces';

export const HospitalTraitList = () => {
  const translate = useTranslate();
  const { tableProps, sorter, tableQueryResult } = useTable<IHospitalTrait>({
    initialSorter: [
      {
        field: 'hospital_id',
        order: 'asc',
      },
    ],
  });

  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate('hospital_trait.fields.id'),
        value: 'id',
      },
      {
        label: translate('hospital_trait.fields.hospital.title'),
        value: 'hospital_id',
      },
      {
        label: translate('hospital_trait.fields.trait.title'),
        value: 'trait_id',
      },
      {
        label: translate('hospital_trait.fields.created_at'),
        value: 'created_at',
      },
      {
        label: translate('hospital_trait.fields.updated_at'),
        value: 'updated_at',
      },
    ],
    [],
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const hospitalIds = tableProps?.dataSource?.map(item => item.hospital_id) ?? [];
  const { data: hospitalData, isLoading } = useMany<IHospital>({
    resource: 'hospitals',
    ids: hospitalIds,
    queryOptions: {
      enabled: hospitalIds.length > 0,
    },
  });
  const { selectProps: hospitalSelectProps } = useSelect<IHospital>({
    resource: 'hospitals',
    optionLabel: 'denomination',
    optionValue: 'id',
  });

  const traitIds = tableProps?.dataSource?.map(item => item.trait_id) ?? [];
  const { data: traitData, isLoading: traitIsLoading } = useMany<ITrait>({
    resource: 'traits',
    ids: traitIds,
    queryOptions: {
      enabled: traitIds.length > 0,
    },
  });
  const { selectProps: traitSelectProps } = useSelect<ITrait>({
    resource: 'traits',
    optionLabel: 'definition',
    optionValue: 'id',
  });

  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(['created_at', 'updated_at', 'id']);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { mutate, isSuccess, isLoading: deleteManyIsLoading } = useDeleteMany<IHospitalTrait>();
  const deleteSelectedItems = () => {
    mutate({
      resource: 'hospital_trait',
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked) setHiddenColumns(prev => prev.filter(col => col !== value));
    else setHiddenColumns(prev => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column<IHospitalTrait>
        key='id'
        dataIndex='id'
        title={translate('answers.fields.id')}
        sorter
        defaultSortOrder={getDefaultSortOrder('id', sorter)}
      />,
      <Table.Column<IHospitalTrait>
        key='hospital_id'
        dataIndex='hospital_id'
        title={translate('hospital_trait.fields.hospital.title')}
        render={(value: string) => {
          if (isLoading) {
            return <TextField value={translate('loading')} />;
          }
          return <TextField value={hospitalData?.data.find((item: IHospital) => item.id === value)?.denomination} />;
        }}
        filterDropdown={props => (
          <FilterDropdown {...props}>
            <Select
              mode='multiple'
              style={{ minWidth: 200 }}
              placeholder={translate('hospital_trait.fields.hospital.filters.placeholder')}
              {...hospitalSelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column<IHospitalTrait>
        key='trait_id'
        dataIndex='trait_id'
        title={translate('hospital_trait.fields.trait.title')}
        render={(value: string) => {
          if (isLoading) {
            return <TextField value={translate('loading')} />;
          }
          return <TextField value={traitData?.data.find(item => item.id === value)?.definition} />;
        }}
        filterDropdown={props => (
          <FilterDropdown {...props}>
            <Select
              mode='multiple'
              style={{ minWidth: 200 }}
              placeholder={translate('hospital_trait.fields.trait.filters.placeholder')}
              {...traitSelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column<IHospitalTrait>
        dataIndex='created_at'
        title={translate('hospital_trait.fields.created_at')}
        render={value => <DateField format='LLL' value={value} />}
        sorter
      />,
      <Table.Column<IHospitalTrait>
        dataIndex='updated_at'
        title={translate('hospital_trait.fields.updated_at')}
        render={value => <DateField format='LLL' value={value} />}
        sorter
      />,
    ],
    [tableProps, sorter, hospitalData, hospitalSelectProps, traitData, traitSelectProps, traitIsLoading, isLoading],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(column => !hiddenColumns.includes(column.props.dataIndex));
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button danger onClick={deleteSelectedItems} disabled={!hasSelected} loading={deleteManyIsLoading}>
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color='#f1c531'>
                {translate('labels.totalNumber', {
                  resource: translate('hospital_trait.hospital_trait').toLowerCase(),
                })}
                {tableQueryResult.data?.total || ''}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>{translate('table.hideColumns.buttonTitle')}</Button>
            </Space>
          ),
        }}>
        <Table<IHospitalTrait>
          rowSelection={rowSelection}
          {...tableProps}
          size='small'
          scroll={{ y: '60vh', x: 10 * visibleColumns.length + 'vw' }}
          pagination={{ ...tableProps.pagination, position: ['topLeft'], size: 'small' }}
          rowKey={'id'}>
          <Table.Column<IHospitalTrait>
            title=''
            dataIndex='actions'
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton title={translate('buttons.show')} size='small' recordItemId={record.id} hideText />
                  <DeleteButton title={translate('buttons.delete')} size='small' recordItemId={record.id} hideText />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction='vertical'>
          <Typography.Text>{translate('table.hideColumns.title')}</Typography.Text>
          {defaultColumns.map(column => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={e => handleHideColumn(column.value, e.target.checked)}>
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
