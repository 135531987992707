import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField, NumberField } from "@pankod/refine-antd";
import { IActivityVolume, IHospital } from 'interfaces';

const { Title, Text } = Typography;

export const VolumeShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IActivityVolume>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: hospitalData } = useOne<IHospital>({
    resource: 'hospitals',
    id: record?.hospital_id || '',
    queryOptions: {
      enabled: !!record?.hospital_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('hospital_activity_volumes.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('hospital_activity_volumes.fields.year')}</Title>
      <NumberField value={record?.year || 1900} />

      <Title level={5}>{translate('hospital_activity_volumes.fields.surgeries_performed')}</Title>
      <NumberField value={record?.surgeries_performed || 0} />

      <Title level={5}>{translate('hospital_activity_volumes.fields.hospital.title')}</Title>
      <Text>{hospitalData?.data.denomination}</Text>

      <Title style={{ marginTop: 16 }} level={5}>
        {translate('hospital_activity_volumes.fields.created_at')}
      </Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('hospital_activity_volumes.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
