import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField, NumberField } from "@pankod/refine-antd";
import { IHospitalTrait, IHospital, IReview, ITrait } from 'interfaces';

const { Title, Text } = Typography;

export const HospitalTraitShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IHospitalTrait>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: hospitalData } = useOne<IHospital>({
    resource: 'hospitals',
    id: record?.hospital_id || '',
    queryOptions: {
      enabled: !!record?.hospital_id,
    },
  });

  const { data: traitData } = useOne<ITrait>({
    resource: 'traits',
    id: record?.trait_id || '',
    queryOptions: {
      enabled: !!record?.trait_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('hospital_trait.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('hospital_trait.fields.hospital.title')}</Title>
      <Text>{hospitalData?.data?.denomination}</Text>

      <Title level={5}>{translate('hospital_trait.fields.trait.title')}</Title>
      <Text>
        {traitData?.data.kind} - {traitData?.data?.definition}
      </Text>

      <Title level={5}>{translate('hospital_trait.fields.created_at')}</Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('hospital_trait.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
