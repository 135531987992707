import { useShow, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField } from "@pankod/refine-antd";
import { ITrait } from 'interfaces';

const { Title, Text } = Typography;

export const TraitShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<ITrait>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('traits.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('traits.fields.kind')}</Title>
      <Text>{record?.kind}</Text>

      <Title level={5}>{translate('traits.fields.definition')}</Title>
      <Text>{record?.definition}</Text>

      <Title level={5}>{translate('traits.fields.description')}</Title>
      <Text>{record?.description || translate('notAvailable')}</Text>

      <Title level={5}>{translate('traits.fields.causes_deprecation')}</Title>
      <BooleanField
        color={record?.causes_deprecation ? '#f5222d' : '#52c41a'}
        value={record?.causes_deprecation}
        trueIcon={<Icons.CheckCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#f5222d' />}
        falseIcon={<Icons.CloseCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#52c41a' />}
        valueLabelFalse={translate('no')}
        valueLabelTrue={translate('yes')}
      />

      <Title style={{ marginTop: 16 }} level={5}>
        {translate('traits.fields.created_at')}
      </Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('traits.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
