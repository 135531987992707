import { useTranslate } from "@pankod/refine-core";

import { Create, Form, Input, useForm } from "@pankod/refine-antd";

import { IExperimentalStudyStage } from "interfaces";

export const StageCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } =
    useForm<IExperimentalStudyStage>();

  return (
    <Create
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate("experimental_studies_stages.fields.name")}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
