import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Edit, useSelect, Select, Create } from "@pankod/refine-antd";
import { IMunicipality, IProvince } from 'interfaces';

export const MunicipalityCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IMunicipality>();

  const { selectProps: provinceSelectProps } = useSelect<IProvince>({
    resource: 'provinces',
    defaultValue: queryResult?.data?.data?.province_id,
    optionLabel: 'denomination',
    optionValue: 'id',
  });

  return (
    <Create isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item
          label={translate('municipalities.fields.denomination')}
          name='denomination'
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={translate('municipalities.fields.province.title')}
          name='province_id'
          rules={[{ required: true }]}>
          <Select {...provinceSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
