import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Edit, useSelect, Select, InputNumber } from "@pankod/refine-antd";
import { IQuestion, ICategory } from 'interfaces';

export const QuestionEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IQuestion>();

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: 'categories',
    defaultValue: queryResult?.data?.data?.category_id,
    optionLabel: 'title',
    optionValue: 'id',
  });

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('questions.fields.content')} name='content' rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label={translate('questions.fields.description')} name='description' rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label={translate('questions.fields.weight')} name='weight' rules={[{ required: true }]}>
          <InputNumber step={0.001} />
        </Form.Item>

        <Form.Item label={translate('questions.fields.category.title')} name='category_id' rules={[{ required: true }]}>
          <Select {...categorySelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
