import React from "react";
import { useTranslate, useDeleteMany, useMany } from "@pankod/refine-core";

import {
  useTable,
  List,
  Table,
  DateField,
  useModal,
  Button,
  Space,
  CreateButton,
  ShowButton,
  EditButton,
  DeleteButton,
  Modal,
  Typography,
  Checkbox,
  FilterDropdown,
  getDefaultFilter,
  Input,
  getDefaultSortOrder,
  useSelect,
  TextField,
  Select,
  Radio,
  BooleanField,
  Icons,
  TagField,
  UrlField,
  Tag,
} from "@pankod/refine-antd";

import { IComment, IQuestion, IReview } from "interfaces";

export const CommentList = () => {
  const translate = useTranslate();
  const { tableProps, sorter, tableQueryResult } = useTable<IComment>({
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
  });

  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate("comments.fields.id"),
        value: "id",
      },
      {
        label: translate("comments.fields.content"),
        value: "content",
      },
      {
        label: translate("comments.fields.is_approved"),
        value: "is_approved",
      },
      {
        label: translate("comments.fields.review.title"),
        value: "review_id",
      },
      {
        label: translate("comments.fields.created_at"),
        value: "created_at",
      },
      {
        label: translate("comments.fields.updated_at"),
        value: "updated_at",
      },
    ],
    [],
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();

  const reviewIds = tableProps?.dataSource?.map((item) => item.review_id) ?? [];
  const { data: reviewData, isLoading: reviewIsLoading } = useMany<IReview>({
    resource: "reviews",
    ids: reviewIds,
    queryOptions: {
      enabled: reviewIds.length > 0,
    },
  });
  const { selectProps: reviewSelectProps } = useSelect<IReview>({
    resource: "reviews",
    optionLabel: "id",
    optionValue: "id",
  });

  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([
    "created_at",
    "updated_at",
    "id",
  ]);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const {
    mutate,
    isSuccess,
    isLoading: deleteManyIsLoading,
  } = useDeleteMany<IComment>();
  const deleteSelectedItems = () => {
    mutate({
      resource: "comments",
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked)
      setHiddenColumns((prev) => prev.filter((col) => col !== value));
    else setHiddenColumns((prev) => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column<IComment>
        key="id"
        dataIndex="id"
        title={translate("comments.fields.id")}
        sorter
        defaultSortOrder={getDefaultSortOrder("id", sorter)}
      />,
      <Table.Column<IComment>
        key="content"
        dataIndex="content"
        title={translate("comments.fields.content")}
      />,
      <Table.Column
        key="is_approved"
        dataIndex="is_approved"
        title={translate("comments.fields.is_approved")}
        render={(value) =>
          value === undefined || value === null ? (
            <TagField value={translate("toBeEvaluated")} color="geekblue" />
          ) : (
            <BooleanField
              color={value ? "#52c41a" : "#f5222d"}
              value={value}
              trueIcon={
                <Icons.CheckCircleTwoTone
                  style={{ fontSize: 18 }}
                  twoToneColor="#52c41a"
                />
              }
              falseIcon={
                <Icons.CloseCircleTwoTone
                  style={{ fontSize: 18 }}
                  twoToneColor="#f5222d"
                />
              }
              valueLabelFalse={translate("no")}
              valueLabelTrue={translate("yes")}
            />
          )
        }
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value="null">{translate("toBeEvaluated")}</Radio>
              <Radio value="true">{translate("yes")}</Radio>
              <Radio value="false">{translate("no")}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key="review_id"
        dataIndex="review_id"
        title={translate("comments.fields.review.title")}
        render={(value: string) => {
          if (reviewIsLoading) {
            return <TextField value={translate("loading")} />;
          }
          return (
            <UrlField
              value={
                "/reviews/show/" +
                reviewData?.data.find((item) => item.id === value)?.id
              }
            >
              {reviewData?.data.find((item) => item.id === value)?.id}
            </UrlField>
          );
        }}
        filterDropdown={(props) => (
          <FilterDropdown
            {...props}
            mapValue={(selectedKeys) =>
              selectedKeys.map((key) => parseInt(key.toString()))
            }
          >
            <Select
              mode="multiple"
              style={{ minWidth: 200 }}
              placeholder={translate(
                "comments.fields.review.filters.placeholder",
              )}
              {...reviewSelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column<IComment>
        key="created_at"
        dataIndex="created_at"
        title={translate("comments.fields.created_at")}
        render={(value) => <DateField format="LLL" value={value} />}
        sorter
      />,
      <Table.Column<IComment>
        key="created_at"
        dataIndex="updated_at"
        title={translate("comments.fields.updated_at")}
        render={(value) => <DateField format="LLL" value={value} />}
        sorter
      />,
    ],
    [tableProps, sorter, reviewData, reviewSelectProps, reviewIsLoading],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(
      (column) => !hiddenColumns.includes(column.props.dataIndex),
    );
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button
              danger
              onClick={deleteSelectedItems}
              disabled={!hasSelected}
              loading={deleteManyIsLoading}
            >
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color="#f1c531">
                {translate("labels.totalNumber", {
                  resource: translate("comments.comments").toLowerCase(),
                })}
                {tableQueryResult.data?.total || ""}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>
                {translate("table.hideColumns.buttonTitle")}
              </Button>
            </Space>
          ),
        }}
      >
        <Table<IComment>
          rowSelection={rowSelection}
          {...tableProps}
          size="small"
          scroll={{ y: "60vh", x: 10 * visibleColumns.length + "vw" }}
          pagination={{
            ...tableProps.pagination,
            position: ["topLeft"],
            size: "small",
          }}
          rowKey="id"
        >
          <Table.Column<IComment>
            title=""
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton
                    title={translate("buttons.show")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <EditButton
                    title={translate("buttons.edit")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <DeleteButton
                    title={translate("buttons.delete")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction="vertical">
          <Typography.Text>
            {translate("table.hideColumns.title")}
          </Typography.Text>
          {defaultColumns.map((column) => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={(e) => handleHideColumn(column.value, e.target.checked)}
            >
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
