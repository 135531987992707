import { useGetLocale, useSetLocale, useGetIdentity } from "@pankod/refine-core";
import { AntdLayout, Space, Menu, Button, Icons, Dropdown, Avatar, Typography } from "@pankod/refine-antd";
import { useTranslation } from 'react-i18next';
import { useSidebarStore } from 'stores/useSidebarStore';
import itLocale from 'antd/lib/locale/it_IT';
import enLocale from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import 'dayjs/locale/en';
import { useLocaleStore } from "stores/useLocaleStore";

const { DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined } = Icons;
const { Text } = Typography;

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity();
  const setCollapsed = useSidebarStore(state => state.setCollapsed);
  const collapsed = useSidebarStore(state => state.collapsed);
  const setCurrentLocale = useLocaleStore(state => state.setLocale);

  const currentLocale = locale();

  const changeLocale = (lang: string) => {
    changeLanguage(lang);
    if (lang === 'en') {
      setCurrentLocale(enLocale);
      dayjs.locale('en');
    }
    else {
      setCurrentLocale(itLocale);
      dayjs.locale('it');
    }
  }

  const menu = (
    <Menu selectedKeys={[currentLocale]}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLocale(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }>
          {lang === 'en' ? 'English' : 'Italian'}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntdLayout.Header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 24px',
        height: '64px',
        backgroundColor: '#FFF',
      }}>
      <div className=''>
        {collapsed ? (
          <MenuUnfoldOutlined style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => setCollapsed(!collapsed)} />
        ) : (
          <MenuFoldOutlined style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => setCollapsed(!collapsed)} />
        )}
      </div>
      <div className=''>
        <Dropdown overlay={menu}>
          <Button type='link'>
            <Space>
              <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
              {currentLocale === 'en' ? 'English' : 'Italian'}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        <Space style={{ marginLeft: '8px' }}>
          {user?.name && (
            <Text ellipsis strong>
              {user.name}
            </Text>
          )}
          {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
        </Space>
      </div>
    </AntdLayout.Header>
  );
};
