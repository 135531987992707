import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Radio, Select, Edit, useSelect } from "@pankod/refine-antd";
import { ITrait } from 'interfaces';

export const TraitEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<ITrait>();

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('traits.fields.kind')} name='kind' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={translate('traits.fields.definition')} name='definition' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={translate('traits.fields.description')} name='description'>
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('traits.fields.causes_deprecation')}
          name='causes_deprecation'
          rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Edit>
  );
};
