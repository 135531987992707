import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField, NumberField } from "@pankod/refine-antd";
import { ICategory } from 'interfaces';

const { Title, Text } = Typography;

export const CategoryShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<ICategory>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('categories.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('categories.fields.title')}</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>{translate('categories.fields.description')}</Title>
      <Text>{record?.description}</Text>

      <Title level={5}>{translate('categories.fields.weight')}</Title>
      <NumberField value={record?.weight || 0.0} />

      <Title level={5}>{translate('categories.fields.created_at')}</Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('categories.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
