import { useTranslate } from "@pankod/refine-core";
import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
} from "@pankod/refine-antd";
import { IExperimentalStudy } from "interfaces";

export const ExperimentalStudyEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } =
    useForm<IExperimentalStudy>();

  const { selectProps: stageSelectProps } = useSelect<IExperimentalStudy>({
    resource: "experimental_studies_stages",
    defaultValue: queryResult?.data?.data?.stage_id,
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate("experimental_studies.fields.stage.title")}
          name="stage_id"
          rules={[{ required: true }]}
        >
          <Select {...stageSelectProps} />
        </Form.Item>
        <Form.Item
          label={translate("experimental_studies.fields.name")}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("experimental_studies.fields.description")}
          name="description"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("experimental_studies.fields.url")}
          name="url"
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
