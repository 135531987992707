import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Edit, useSelect, Select, Radio } from "@pankod/refine-antd";
import { IComment, IReview } from 'interfaces';

export const CommentEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IComment>();

  const { selectProps: reviewSelectProps } = useSelect<IReview>({
    resource: 'reviews',
    defaultValue: queryResult?.data?.data?.review_id,
    optionLabel: 'id',
    optionValue: 'id',
  });

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('comments.fields.content')} name='content' rules={[{ required: true }]}>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label={translate('comments.fields.is_approved')} name='is_approved'>
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
            <Radio value={null}>Attendi</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={translate('comments.fields.review.title')} name='review_id' rules={[{ required: true }]}>
          <Select {...reviewSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
