import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField, TagField } from "@pankod/refine-antd";
import { IComment, IHospital, IReview } from 'interfaces';

const { Title, Text } = Typography;

export const CommentShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IComment>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: reviewData } = useOne<IReview>({
    resource: 'reviews',
    id: record?.review_id || '',
    queryOptions: {
      enabled: !!record?.review_id,
    },
  });

  const { data: hospitalData } = useOne<IHospital>({
    resource: 'hospitals',
    id: reviewData?.data?.hospital_id || '',
    queryOptions: {
      enabled: !!reviewData?.data?.hospital_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('comments.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('comments.fields.content')}</Title>
      <Text>{record?.content}</Text>

      <Title level={5}>{translate('comments.fields.is_approved')}</Title>
      {record?.is_approved === null || record?.is_approved === undefined ? (
        <TagField value={translate('toBeEvaluated')} color='geekblue' />
      ) : (
        <BooleanField
          color={record?.is_approved ? '#52c41a' : '#f5222d'}
          value={record?.is_approved}
          trueIcon={<Icons.CheckCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#52c41a' />}
          falseIcon={<Icons.CloseCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#f5222d' />}
          valueLabelFalse={translate('no')}
          valueLabelTrue={translate('yes')}
        />
      )}

      <Title style={{ marginTop: 16 }} level={5}>
        {translate('comments.fields.review.title')}
      </Title>
      <Text>
        {record?.review_id} - {hospitalData?.data?.denomination}
      </Text>

      <Title level={5}>{translate('comments.fields.created_at')}</Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('comments.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
