import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Edit, useSelect, Select, Create } from "@pankod/refine-antd";
import { IProvince, IRegion } from 'interfaces';

export const ProvinceCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IProvince>();

  const { selectProps: regionSelectProps } = useSelect<IRegion>({
    resource: 'regions',
    defaultValue: queryResult?.data?.data?.region_id,
    optionLabel: 'denomination',
    optionValue: 'id',
  });

  return (
    <Create isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('provinces.fields.denomination')} name='denomination' rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label={translate('provinces.fields.abbreviation')} name='abbreviation' rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label={translate('provinces.fields.region.title')} name='region_id' rules={[{ required: true }]}>
          <Select {...regionSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
