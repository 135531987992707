import { useShow, useTranslate } from "@pankod/refine-core";
import { Show, Typography, DateField } from "@pankod/refine-antd";
import { IExperimentalStudyStage } from "interfaces";

const { Title, Text } = Typography;

export const StageShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IExperimentalStudyStage>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>
        {translate("experimental_studies_stages.fields.id")}
      </Title>
      <Text>{record?.id}</Text>

      <Title level={5}>
        {translate("experimental_studies_stages.fields.name")}
      </Title>
      <Text>{record?.name}</Text>

      <Title style={{ marginTop: 16 }} level={5}>
        {translate("experimental_studies_stages.fields.created_at")}
      </Title>
      <DateField format="lll" value={record?.created_at} />

      <Title level={5}>
        {translate("experimental_studies_stages.fields.updated_at")}
      </Title>
      <DateField format="lll" value={record?.updated_at} />
    </Show>
  );
};
