import React from 'react';
import { useTranslate, useDeleteMany } from "@pankod/refine-core";

import {
  useTable,
  List,
  Table,
  DateField,
  useModal,
  Button,
  Space,
  CreateButton,
  ShowButton,
  EditButton,
  DeleteButton,
  Modal,
  Typography,
  Checkbox,
  getDefaultSortOrder,
  Tag,
} from "@pankod/refine-antd";

import { IMessage } from 'interfaces';

export const MessageList = () => {
  const translate = useTranslate();
  const { tableProps, sorter, tableQueryResult } = useTable<IMessage>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc',
      },
    ],
  });

  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate('messages.fields.id'),
        value: 'id',
      },
      {
        label: translate('messages.fields.from'),
        value: 'from',
      },
      {
        label: translate('messages.fields.subject'),
        value: 'subject',
      },
      {
        label: translate('messages.fields.content'),
        value: 'content',
      },
      {
        label: translate('messages.fields.created_at'),
        value: 'created_at',
      },
      {
        label: translate('messages.fields.updated_at'),
        value: 'updated_at',
      },
    ],
    [],
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(['created_at', 'updated_at', 'id']);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { mutate, isSuccess, isLoading: deleteManyIsLoading } = useDeleteMany<IMessage>();
  const deleteSelectedItems = () => {
    mutate({
      resource: 'messages',
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked) setHiddenColumns(prev => prev.filter(col => col !== value));
    else setHiddenColumns(prev => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column<IMessage>
        key='id'
        dataIndex='id'
        title={translate('messages.fields.id')}
        sorter
        defaultSortOrder={getDefaultSortOrder('id', sorter)}
      />,
      <Table.Column<IMessage> key='from' dataIndex='from' title={translate('messages.fields.from')} />,
      <Table.Column<IMessage> key='subject' dataIndex='subject' title={translate('messages.fields.subject')} />,
      <Table.Column<IMessage> key='content' dataIndex='content' title={translate('messages.fields.content')} />,
      <Table.Column<IMessage>
        key='created_at'
        dataIndex='created_at'
        title={translate('messages.fields.created_at')}
        render={value => <DateField format='LLL' value={value} />}
        sorter
      />,
      <Table.Column<IMessage>
        key='updated_at'
        dataIndex='updated_at'
        title={translate('messages.fields.updated_at')}
        render={value => <DateField format='LLL' value={value} />}
        sorter
      />,
    ],
    [tableProps, sorter],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(column => !hiddenColumns.includes(column.props.dataIndex));
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button danger onClick={deleteSelectedItems} disabled={!hasSelected} loading={deleteManyIsLoading}>
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color='#f1c531'>
                {translate('labels.totalNumber', { resource: translate('messages.messages').toLowerCase() })}
                {tableQueryResult.data?.total || ''}
              </Tag>
              <Button onClick={columnsShow}>{translate('table.hideColumns.buttonTitle')}</Button>
            </Space>
          ),
        }}>
        <Table<IMessage>
          rowSelection={rowSelection}
          {...tableProps}
          size='small'
          scroll={{ y: '60vh', x: 10 * visibleColumns.length + 'vw' }}
          pagination={{ ...tableProps.pagination, position: ['topLeft'], size: 'small' }}
          rowKey='id'>
          <Table.Column<IMessage>
            title=''
            dataIndex='actions'
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton title={translate('buttons.show')} size='small' recordItemId={record.id} hideText />
                  <DeleteButton title={translate('buttons.delete')} size='small' recordItemId={record.id} hideText />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction='vertical'>
          <Typography.Text>{translate('table.hideColumns.title')}</Typography.Text>
          {defaultColumns.map(column => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={e => handleHideColumn(column.value, e.target.checked)}>
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
