import { SuspenseFallback } from 'components/SuspenseFallback';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<SuspenseFallback />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
