import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, DateField, UrlField } from "@pankod/refine-antd";
import { IExperimentalStudy, IExperimentalStudyStage } from "interfaces";

const { Title, Text } = Typography;

export const ExperimentalStudyShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IExperimentalStudy>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: stageData } = useOne<IExperimentalStudyStage>({
    resource: "experimental_studies_stages",
    id: record?.stage_id || "",
    queryOptions: {
      enabled: !!record?.stage_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate("experimental_studies.fields.id")}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>
        {translate("experimental_studies.fields.stage.title")}
      </Title>
      <Text>{stageData?.data?.name}</Text>

      <Title level={5}>{translate("experimental_studies.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>
        {translate("experimental_studies.fields.description")}
      </Title>
      <Text>{record?.description}</Text>

      <Title level={5}>{translate("experimental_studies.fields.url")}</Title>
      <UrlField value={record?.url} />

      <Title level={5}>
        {translate("experimental_studies.fields.created_at")}
      </Title>
      <DateField format="lll" value={record?.created_at} />

      <Title level={5}>
        {translate("experimental_studies.fields.updated_at")}
      </Title>
      <DateField format="lll" value={record?.updated_at} />
    </Show>
  );
};
