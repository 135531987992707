import React from 'react';
import { useTranslate, useDeleteMany } from "@pankod/refine-core";

import {
  useTable,
  List,
  Table,
  DateField,
  useModal,
  Button,
  Space,
  CreateButton,
  ShowButton,
  EditButton,
  DeleteButton,
  Modal,
  Typography,
  Checkbox,
  FilterDropdown,
  getDefaultFilter,
  Input,
  getDefaultSortOrder,
  NumberField,
  Tag,
} from "@pankod/refine-antd";

import { ICategory } from 'interfaces';

export const CategoryList = () => {
  const translate = useTranslate();
  const { tableProps, sorter, filters, tableQueryResult } = useTable<ICategory>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc',
      },
    ],
    initialFilter: [
      {
        field: 'title',
        operator: 'contains',
        value: undefined,
      },
    ],
  });

  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate('categories.fields.id'),
        value: 'id',
      },
      {
        label: translate('categories.fields.title'),
        value: 'title',
      },
      {
        label: translate('categories.fields.description'),
        value: 'description',
      },
      {
        label: translate('categories.fields.weight'),
        value: 'weight',
      },
      {
        label: translate('categories.fields.created_at'),
        value: 'created_at',
      },
      {
        label: translate('categories.fields.updated_at'),
        value: 'updated_at',
      },
    ],
    [],
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(['created_at', 'updated_at', 'id', 'weight']);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { mutate, isSuccess, isLoading: deleteManyIsLoading } = useDeleteMany<ICategory>();
  const deleteSelectedItems = () => {
    mutate({
      resource: 'categories',
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked) setHiddenColumns(prev => prev.filter(col => col !== value));
    else setHiddenColumns(prev => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column<ICategory>
        key='id'
        dataIndex='id'
        title={translate('categories.fields.id')}
        sorter
        defaultSortOrder={getDefaultSortOrder('id', sorter)}
      />,
      <Table.Column<ICategory>
        key='title'
        dataIndex='title'
        title={translate('categories.fields.title')}
        filterDropdown={props => (
          <FilterDropdown {...props}>
            <Input placeholder={translate('labels.search')} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter('title', filters)}
      />,
      <Table.Column<ICategory>
        key='description'
        dataIndex='description'
        title={translate('categories.fields.description')}
      />,
      <Table.Column<ICategory>
        key='weight'
        dataIndex='weight'
        title={translate('categories.fields.weight')}
        render={value => <NumberField value={value} />}
      />,
      <Table.Column<ICategory>
        key='created_at'
        dataIndex='created_at'
        title={translate('categories.fields.created_at')}
        render={value => <DateField format='LLL' value={value} />}
        sorter
      />,
      <Table.Column<ICategory>
        key='updated_at'
        dataIndex='updated_at'
        title={translate('categories.fields.updated_at')}
        render={value => <DateField format='LLL' value={value} />}
        sorter
      />,
    ],
    [tableProps, filters, sorter],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(column => !hiddenColumns.includes(column.props.dataIndex));
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button danger onClick={deleteSelectedItems} disabled={!hasSelected} loading={deleteManyIsLoading}>
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color='#f1c531'>
                {translate('labels.totalNumber', { resource: translate('categories.categories').toLowerCase() })}
                {tableQueryResult.data?.total || ''}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>{translate('table.hideColumns.buttonTitle')}</Button>
            </Space>
          ),
        }}>
        <Table<ICategory>
          rowSelection={rowSelection}
          {...tableProps}
          size='small'
          scroll={{ y: '60vh', x: 10 * visibleColumns.length + 'vw' }}
          pagination={{ ...tableProps.pagination, position: ['topLeft'], size: 'small' }}
          rowKey='id'>
          <Table.Column<ICategory>
            title=''
            dataIndex='actions'
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton title={translate('buttons.show')} size='small' recordItemId={record.id} hideText />
                  <EditButton title={translate('buttons.edit')} size='small' recordItemId={record.id} hideText />
                  <DeleteButton title={translate('buttons.delete')} size='small' recordItemId={record.id} hideText />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction='vertical'>
          <Typography.Text>{translate('table.hideColumns.title')}</Typography.Text>
          {defaultColumns.map(column => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={e => handleHideColumn(column.value, e.target.checked)}>
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
