import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField, NumberField } from "@pankod/refine-antd";
import { IMessage } from 'interfaces';

const { Title, Text } = Typography;

export const MessageShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IMessage>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('messages.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('messages.fields.from')}</Title>
      <Text>{record?.from}</Text>

      <Title level={5}>{translate('messages.fields.subject')}</Title>
      <Text>{record?.subject}</Text>

      <Title level={5}>{translate('messages.fields.content')}</Title>
      <Text>{record?.content}</Text>

      <Title level={5}>{translate('messages.fields.created_at')}</Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('messages.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
