import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Radio, Select, Create, useSelect } from "@pankod/refine-antd";
import { IReview } from 'interfaces';

export const ReviewCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IReview>();

  const { selectProps: hospitalSelectProps } = useSelect<IReview>({
    resource: 'hospitals',
    defaultValue: queryResult?.data?.data?.hospital_id,
    optionLabel: 'denomination',
    optionValue: 'id',
  });

  return (
    <Create isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('reviews.fields.hospital.title')} name='hospital_id' rules={[{ required: true }]}>
          <Select {...hospitalSelectProps} />
        </Form.Item>
        <Form.Item label={translate('reviews.fields.is_deprecated')} name='is_deprecated' rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Create>
  );
};
