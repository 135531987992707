import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, useSelect, Select, Create } from "@pankod/refine-antd";
import { IHospital, IHospitalTrait, ITrait } from 'interfaces';

export const HospitalTraitCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IHospitalTrait>();

  const { selectProps: hospitalSelectProps } = useSelect<IHospital>({
    resource: 'hospitals',
    defaultValue: queryResult?.data?.data?.hospital_id,
    optionLabel: 'denomination',
    optionValue: 'id',
  });

  const { selectProps: traitSelectProps } = useSelect<ITrait>({
    resource: 'traits',
    defaultValue: queryResult?.data?.data?.trait_id,
    optionLabel: 'definition',
    optionValue: 'id',
  });

  return (
    <Create isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item
          label={translate('hospital_trait.fields.hospital.title')}
          name='hospital_id'
          rules={[{ required: true }]}>
          <Select {...hospitalSelectProps} />
        </Form.Item>

        <Form.Item label={translate('hospital_trait.fields.trait.title')} name='trait_id' rules={[{ required: true }]}>
          <Select {...traitSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
