import React from 'react';
import { useTranslate } from "@pankod/refine-core";
import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button, Checkbox } from "@pankod/refine-antd";
import './styles.css';

import { useLogin } from "@pankod/refine-core";

const { Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
}

export const Login: React.FC = () => {
  const translate = useTranslate();
  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title level={3} className='title'>
      {translate('pages.login.title')}
    </Title>
  );

  return (
    <AntdLayout className='layout'>
      <Row
        justify='center'
        align='middle'
        style={{
          height: '100vh',
        }}>
        <Col xs={22}>
          <div className='container'>
            <div className='imageContainer'>
              <img className='image' src='./uha.svg' alt='Uro-H-Advisor Logo' />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout='vertical'
                form={form}
                onFinish={values => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{}}>
                <Form.Item name='username' label={translate('pages.login.username')} rules={[{ required: true }]}>
                  <Input size='middle' placeholder={translate('pages.login.username')} />
                </Form.Item>
                <Form.Item
                  name='password'
                  label='Password'
                  rules={[{ required: true }]}
                  style={{ marginBottom: '18px' }}>
                  <Input type='password' placeholder='●●●●●●●●' size='middle' />
                </Form.Item>
                <Button type='primary' size='middle' htmlType='submit' block>
                  {translate('pages.login.signin')}
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
