import React from "react";
import { useTranslate, useDeleteMany, useMany } from "@pankod/refine-core";

import {
  useTable,
  List,
  Table,
  DateField,
  useModal,
  Button,
  Space,
  CreateButton,
  ShowButton,
  EditButton,
  DeleteButton,
  Modal,
  Typography,
  Checkbox,
  FilterDropdown,
  getDefaultFilter,
  Input,
  getDefaultSortOrder,
  Tag,
  Select,
  useSelect,
  TextField,
} from "@pankod/refine-antd";

import { IExperimentalStudy, IExperimentalStudyStage } from "interfaces";

export const ExperimentalStudyList = () => {
  const translate = useTranslate();
  const { tableProps, sorter, filters, tableQueryResult } =
    useTable<IExperimentalStudy>({
      initialSorter: [
        {
          field: "id",
          order: "asc",
        },
      ],
      initialFilter: [
        {
          field: "name",
          operator: "contains",
          value: undefined,
        },
      ],
    });

  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate("experimental_studies.fields.id"),
        value: "id",
      },
      {
        label: translate("experimental_studies.fields.stage.title"),
        value: "stage_id",
      },
      {
        label: translate("experimental_studies.fields.name"),
        value: "name",
      },
      {
        label: translate("experimental_studies.fields.description"),
        value: "description",
      },
      {
        label: translate("experimental_studies.fields.url"),
        value: "url",
      },
      {
        label: translate("experimental_studies.fields.created_at"),
        value: "created_at",
      },
      {
        label: translate("experimental_studies.fields.updated_at"),
        value: "updated_at",
      },
    ],
    [],
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();

  const stageIds = tableProps?.dataSource?.map((item) => item.stage_id) ?? [];
  const { data: stageData, isLoading } = useMany<IExperimentalStudyStage>({
    resource: "experimental_studies_stages",
    ids: stageIds,
    queryOptions: {
      enabled: stageIds.length > 0,
    },
  });
  const { selectProps: stageSelectProps } = useSelect<IExperimentalStudyStage>({
    resource: "experimental_studies_stages",
    optionLabel: "name",
    optionValue: "id",
  });
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([
    "created_at",
    "updated_at",
    "description",
    "id",
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const {
    mutate,
    isSuccess,
    isLoading: deleteManyIsLoading,
  } = useDeleteMany<IExperimentalStudy>();
  const deleteSelectedItems = () => {
    mutate({
      resource: "experimental_studies",
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked)
      setHiddenColumns((prev) => prev.filter((col) => col !== value));
    else setHiddenColumns((prev) => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column<IExperimentalStudy>
        key="id"
        dataIndex="id"
        title={translate("experimental_studies.fields.id")}
        sorter
        defaultSortOrder={getDefaultSortOrder("id", sorter)}
      />,
      <Table.Column<IExperimentalStudy>
        key="stage_id"
        dataIndex="stage_id"
        title={translate("experimental_studies.fields.stage.title")}
        render={(value: string) => {
          if (isLoading) {
            return <TextField value={translate("loading")} />;
          }
          return (
            <TextField
              value={
                stageData?.data.find(
                  (item: IExperimentalStudyStage) => item.id === value,
                )?.name
              }
            />
          );
        }}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              mode="multiple"
              style={{ minWidth: 200 }}
              placeholder={translate(
                "experimental_studies.fields.filters.placeholder",
              )}
              {...stageSelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column<IExperimentalStudy>
        key="name"
        dataIndex="name"
        title={translate("experimental_studies.fields.name")}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder={translate("labels.search")} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter("name", filters)}
      />,
      <Table.Column<IExperimentalStudy>
        key="description"
        dataIndex="description"
        title={translate("experimental_studies.fields.description")}
      />,
      <Table.Column<IExperimentalStudy>
        key="url"
        dataIndex="url"
        title={translate("experimental_studies.fields.url")}
      />,
      <Table.Column<IExperimentalStudy>
        key="created_at"
        dataIndex="created_at"
        title={translate("experimental_studies.fields.created_at")}
        render={(value) => <DateField format="LLL" value={value} />}
        sorter
      />,
      <Table.Column<IExperimentalStudy>
        key="updated_at"
        dataIndex="updated_at"
        title={translate("experimental_studies.fields.updated_at")}
        render={(value) => <DateField format="LLL" value={value} />}
        sorter
      />,
    ],
    [tableProps, filters, sorter],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(
      (column) => !hiddenColumns.includes(column.props.dataIndex),
    );
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button
              danger
              onClick={deleteSelectedItems}
              disabled={!hasSelected}
              loading={deleteManyIsLoading}
            >
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color="#f1c531">
                {translate("labels.totalNumber", {
                  resource: translate(
                    "experimental_studies.experimental_studies",
                  ).toLowerCase(),
                })}
                {tableQueryResult.data?.total || ""}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>
                {translate("table.hideColumns.buttonTitle")}
              </Button>
            </Space>
          ),
        }}
      >
        <Table<IExperimentalStudy>
          rowSelection={rowSelection}
          {...tableProps}
          size="small"
          scroll={{ y: "60vh", x: 10 * visibleColumns.length + "vw" }}
          pagination={{
            ...tableProps.pagination,
            position: ["topLeft"],
            size: "small",
          }}
          rowKey="id"
        >
          <Table.Column<IExperimentalStudy>
            title=""
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton
                    title={translate("buttons.show")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <EditButton
                    title={translate("buttons.edit")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <DeleteButton
                    title={translate("buttons.delete")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction="vertical">
          <Typography.Text>
            {translate("table.hideColumns.title")}
          </Typography.Text>
          {defaultColumns.map((column) => (
            <Checkbox
              key={column.label + column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={(e) => handleHideColumn(column.value, e.target.checked)}
            >
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
