import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField, NumberField } from "@pankod/refine-antd";
import { IAnswer, IHospital, IQuestion, IReview } from 'interfaces';

const { Title, Text } = Typography;

export const AnswerShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IAnswer>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: questionData } = useOne<IQuestion>({
    resource: 'questions',
    id: record?.question_id || '',
    queryOptions: {
      enabled: !!record?.question_id,
    },
  });

  const { data: reviewData } = useOne<IReview>({
    resource: 'reviews',
    id: record?.review_id || '',
    queryOptions: {
      enabled: !!record?.review_id,
    },
  });

  const { data: hospitalData } = useOne<IHospital>({
    resource: 'hospitals',
    id: reviewData?.data.hospital_id || '',
    queryOptions: {
      enabled: !!reviewData?.data.hospital_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('answers.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('answers.fields.rating')}</Title>
      <NumberField value={record?.rating || 1} />

      <Title level={5}>{translate('answers.fields.question.title')}</Title>
      <Text>{questionData?.data?.content}</Text>

      <Title level={5}>{translate('answers.fields.review.title')}</Title>
      <Text>
        {record?.review_id} - {hospitalData?.data?.denomination}
      </Text>

      <Title level={5}>{translate('answers.fields.created_at')}</Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('answers.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
