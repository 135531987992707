import React from 'react';

import { LayoutProps } from "@pankod/refine-core";
import { AntdLayout, Grid } from "@pankod/refine-antd";
import { useSidebarStore } from 'stores/useSidebarStore';

export const Layout: React.FC<LayoutProps> = ({ children, Sider, Header, Footer, OffLayoutArea }) => {
  const breakpoint = Grid.useBreakpoint();
  const collapsed = useSidebarStore(state => state.collapsed);
  return (
    <AntdLayout style={{ minHeight: '100vh', flexDirection: 'row' }}>
      {Sider && <Sider />}
      <AntdLayout
        style={{
          marginLeft: collapsed ? (breakpoint.lg ? 80 : 0) : 200,
          transition: 'margin .2s ease',
        }}>
        {Header && <Header />}
        <AntdLayout.Content>
          <div
            style={{
              padding: breakpoint.sm ? 24 : 12,
              minHeight: 360,
            }}>
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
    </AntdLayout>
  );
};
