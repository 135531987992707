import create from 'zustand';

type SidebarStore = {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
};

export const useSidebarStore = create<SidebarStore>(set => ({
  collapsed: false,
  setCollapsed: value => set({ collapsed: value }),
}));
