import React from 'react';
import routerProvider from '@pankod/refine-react-router-v6';
import { TitleProps } from "@pankod/refine-core";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <div
    style={{
      width: '100%',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <Link to='/'>
      {collapsed ? (
        <img
          src={'/uha-collapsed.svg'}
          alt='Uro-H-Advisor'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2px 2px',
            maxHeight: '64px',
          }}
        />
      ) : (
        <img
          src={'/uha.svg'}
          alt='Uro-H-Advisor'
          style={{
            height: '64px',
            padding: '12px 0px',
          }}
        />
      )}
    </Link>
  </div>
);
