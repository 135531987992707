import { useTranslate } from '@pankod/refine-core';
import { useForm, Form, Input, Radio, Select, Edit, useSelect, InputNumber } from '@pankod/refine-antd';
import { IDoctor, IDoctorRole } from 'interfaces';

export const DoctorRoleEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IDoctorRole>();

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item
          label={translate('referring_doctors_roles.fields.role_name')}
          name='role_name'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate('referring_doctors_roles.fields.sorting_order')}
          name='sorting_order'
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  );
};
