import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, useSelect, Select, Create } from "@pankod/refine-antd";
import {
  IHospital,
  IHospitalStudy,
  IExperimentalStudy,
  IDoctor,
} from "interfaces";

export const HospitalStudyCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IHospitalStudy>();

  const { selectProps: hospitalSelectProps } = useSelect<IHospital>({
    resource: "hospitals",
    defaultValue: queryResult?.data?.data?.hospital_id,
    optionLabel: "denomination",
    optionValue: "id",
  });

  const { selectProps: studySelectProps } = useSelect<IExperimentalStudy>({
    resource: "experimental_studies",
    defaultValue: queryResult?.data?.data?.study_id,
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: doctorSelectProps } = useSelect<IDoctor>({
    resource: "referring_doctors",
    defaultValue: queryResult?.data?.data?.doctor_id,
    optionLabel: "last_name",
    optionValue: "id",
  });

  return (
    <Create
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate("hospital_study.fields.hospital.title")}
          name="hospital_id"
          rules={[{ required: true }]}
        >
          <Select {...hospitalSelectProps} />
        </Form.Item>

        <Form.Item
          label={translate("hospital_study.fields.study.title")}
          name="study_id"
          rules={[{ required: true }]}
        >
          <Select {...studySelectProps} />
        </Form.Item>

        <Form.Item
          label={translate("hospital_study.fields.doctor.title")}
          name="doctor_id"
          rules={[{ required: false }]}
        >
          <Select {...doctorSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
