import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, InputNumber, Radio, Select, Edit, useSelect } from "@pankod/refine-antd";
import { IActivityVolume } from 'interfaces';

export const VolumeEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IActivityVolume>();

  const { selectProps: hospitalSelectProps } = useSelect<IActivityVolume>({
    resource: 'hospitals',
    defaultValue: queryResult?.data?.data?.hospital_id,
    optionLabel: 'denomination',
    optionValue: 'id',
  });

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('hospital_activity_volumes.fields.year')} name='year' rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={translate('hospital_activity_volumes.fields.surgeries_performed')}
          name='surgeries_performed'
          rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={translate('hospital_activity_volumes.fields.hospital.title')}
          name='hospital_id'
          rules={[{ required: true }]}>
          <Select {...hospitalSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
