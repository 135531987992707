import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField, NumberField } from "@pankod/refine-antd";
import { IQuestion, ICategory } from 'interfaces';

const { Title, Text } = Typography;

export const QuestionShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IQuestion>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: categoryData } = useOne<ICategory>({
    resource: 'categories',
    id: record?.category_id || '',
    queryOptions: {
      enabled: !!record?.category_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('questions.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('questions.fields.content')}</Title>
      <Text>{record?.content}</Text>

      <Title level={5}>{translate('questions.fields.description')}</Title>
      <Text>{record?.description}</Text>

      <Title level={5}>{translate('questions.fields.weight')}</Title>
      <NumberField value={record?.weight || 0.0} />

      <Title level={5}>{translate('questions.fields.category.title')}</Title>
      <Text>{categoryData?.data.title}</Text>

      <Title level={5}>{translate('questions.fields.created_at')}</Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('questions.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
