import React from "react";
import { useMany, useTranslate, useDeleteMany } from "@pankod/refine-core";

import {
  Input,
  useTable,
  List,
  Table,
  DateField,
  Select,
  useSelect,
  TextField,
  FilterDropdown,
  ShowButton,
  Space,
  EditButton,
  DeleteButton,
  Radio,
  getDefaultSortOrder,
  BooleanField,
  Button,
  CreateButton,
  useModal,
  Modal,
  Checkbox,
  Typography,
  Icons,
  getDefaultFilter,
  Tag,
} from "@pankod/refine-antd";

import { IHospital, IMunicipality } from "interfaces";

export const HospitalList = () => {
  const translate = useTranslate();
  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate("hospitals.fields.id"),
        value: "id",
      },
      {
        label: translate("hospitals.fields.facility_code"),
        value: "facility_code",
      },
      {
        label: translate("hospitals.fields.denomination"),
        value: "denomination",
      },
      {
        label: translate("hospitals.fields.municipality.title"),
        value: "municipality_id",
      },
      {
        label: translate("hospitals.fields.address"),
        value: "address",
      },
      {
        label: translate("hospitals.fields.zip_code"),
        value: "zip_code",
      },
      {
        label: translate("hospitals.fields.website"),
        value: "website",
      },
      {
        label: translate("hospitals.fields.email"),
        value: "email",
      },
      {
        label: translate("hospitals.fields.phone_number"),
        value: "phone_number",
      },
      {
        label: translate("hospitals.fields.infos"),
        value: "infos",
      },
      {
        label: translate("hospitals.fields.has_agreement"),
        value: "has_agreement",
      },
      {
        label: translate("hospitals.fields.is_deprecated"),
        value: "is_deprecated",
      },
      {
        label: translate("hospitals.fields.does_research"),
        value: "does_research",
      },
      {
        label: translate("hospitals.fields.is_accredited"),
        value: "is_accredited",
      },
      {
        label: translate("hospitals.fields.has_multidisciplinary_team"),
        value: "has_multidisciplinary_team",
      },
      {
        label: translate("hospitals.fields.has_pdta"),
        value: "has_pdta",
      },
      {
        label: translate("hospitals.fields.created_at"),
        value: "created_at",
      },
      {
        label: translate("hospitals.fields.updated_at"),
        value: "updated_at",
      },
    ],
    [],
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([
    "created_at",
    "updated_at",
    "id",
    "zip_code",
    "website",
    "email",
    "infos",
    "phone_number",
    "facility_code",
    "has_agreement",
    "is_accredited",
    "has_multidisciplinary_team",
    "has_pdta",
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { tableProps, filters, sorter, tableQueryResult } = useTable<IHospital>(
    {
      initialSorter: [
        {
          field: "id",
          order: "asc",
        },
      ],
      initialFilter: [
        {
          field: "denomination",
          operator: "contains",
          value: undefined,
        },
      ],
    },
  );
  const municipalityIds =
    tableProps?.dataSource?.map((item) => item.municipality_id) ?? [];
  const { data: municipalityData, isLoading } = useMany<IMunicipality>({
    resource: "municipalities",
    ids: municipalityIds,
    queryOptions: {
      enabled: municipalityIds.length > 0,
    },
  });
  const { selectProps: municipalitySelectProps } = useSelect<IMunicipality>({
    resource: "municipalities",
    optionLabel: "denomination",
    optionValue: "id",
  });
  const {
    mutate,
    isSuccess,
    isLoading: deleteManyIsLoading,
  } = useDeleteMany<IHospital>();
  const deleteSelectedItems = () => {
    mutate({
      resource: "hospitals",
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked)
      setHiddenColumns((prev) => prev.filter((col) => col !== value));
    else setHiddenColumns((prev) => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column
        key="id"
        dataIndex="id"
        title={translate("hospitals.fields.id")}
        sorter
        defaultSortOrder={getDefaultSortOrder("id", sorter)}
      />,
      <Table.Column
        key="facility_code"
        dataIndex="facility_code"
        title={translate("hospitals.fields.facility_code")}
      />,
      <Table.Column
        key="denomination"
        dataIndex="denomination"
        title={translate("hospitals.fields.denomination")}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder={translate("labels.search")} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter("denomination", filters)}
      />,
      <Table.Column
        key="municipality_id"
        dataIndex="municipality_id"
        title={translate("hospitals.fields.municipality.title")}
        render={(value: string) => {
          if (isLoading) {
            return <TextField value={translate("loading")} />;
          }
          return (
            <TextField
              value={
                municipalityData?.data.find(
                  (item: IMunicipality) => item.id === value,
                )?.denomination
              }
            />
          );
        }}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              mode="multiple"
              style={{ minWidth: 200 }}
              placeholder={translate(
                "hospitals.fields.municipality.filters.placeholder",
              )}
              {...municipalitySelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key="address"
        dataIndex="address"
        title={translate("hospitals.fields.address")}
      />,
      <Table.Column
        key="zip_code"
        dataIndex="zip_code"
        title={translate("hospitals.fields.zip_code")}
      />,
      <Table.Column
        key="website"
        dataIndex="website"
        title={translate("hospitals.fields.website")}
      />,
      <Table.Column
        key="email"
        dataIndex="email"
        title={translate("hospitals.fields.email")}
      />,
      <Table.Column
        key="phone_number"
        dataIndex="phone_number"
        title={translate("hospitals.fields.phone_number")}
      />,
      <Table.Column
        key="infos"
        dataIndex="infos"
        title={translate("hospitals.fields.infos")}
      />,
      <Table.Column
        key="has_agreement"
        dataIndex="has_agreement"
        title={translate("hospitals.fields.has_agreement")}
        render={(value) => (
          <BooleanField
            color={value ? "#52c41a" : "#f5222d"}
            value={value}
            trueIcon={
              <Icons.CheckCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#52c41a"
              />
            }
            falseIcon={
              <Icons.CloseCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#f5222d"
              />
            }
            valueLabelFalse={translate("no")}
            valueLabelTrue={translate("yes")}
          />
        )}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value="true">{translate("yes")}</Radio>
              <Radio value="false">{translate("no")}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key="is_deprecated"
        dataIndex="is_deprecated"
        title={translate("hospitals.fields.is_deprecated")}
        render={(value) => (
          <BooleanField
            color={value ? "#f5222d" : "#52c41a"}
            value={value}
            trueIcon={
              <Icons.CheckCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#f5222d"
              />
            }
            falseIcon={
              <Icons.CloseCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#52c41a"
              />
            }
            valueLabelFalse={translate("no")}
            valueLabelTrue={translate("yes")}
          />
        )}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value="true">{translate("yes")}</Radio>
              <Radio value="false">{translate("no")}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key="does_research"
        dataIndex="does_research"
        title={translate("hospitals.fields.does_research")}
        render={(value) => (
          <BooleanField
            color={value ? "#52c41a" : "#f5222d"}
            value={value}
            trueIcon={
              <Icons.CheckCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#52c41a"
              />
            }
            falseIcon={
              <Icons.CloseCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#f5222d"
              />
            }
            valueLabelFalse={translate("no")}
            valueLabelTrue={translate("yes")}
          />
        )}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value="true">{translate("yes")}</Radio>
              <Radio value="false">{translate("no")}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key="is_accredited"
        dataIndex="is_accredited"
        title={translate("hospitals.fields.is_accredited")}
        render={(value) => (
          <BooleanField
            color={value ? "#52c41a" : "#f5222d"}
            value={value}
            trueIcon={
              <Icons.CheckCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#52c41a"
              />
            }
            falseIcon={
              <Icons.CloseCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#f5222d"
              />
            }
            valueLabelFalse={translate("no")}
            valueLabelTrue={translate("yes")}
          />
        )}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value="true">{translate("yes")}</Radio>
              <Radio value="false">{translate("no")}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key="has_multidisciplinary_team"
        dataIndex="has_multidisciplinary_team"
        title={translate("hospitals.fields.has_multidisciplinary_team")}
        render={(value) => (
          <BooleanField
            color={value ? "#52c41a" : "#f5222d"}
            value={value}
            trueIcon={
              <Icons.CheckCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#52c41a"
              />
            }
            falseIcon={
              <Icons.CloseCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#f5222d"
              />
            }
            valueLabelFalse={translate("no")}
            valueLabelTrue={translate("yes")}
          />
        )}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value="true">{translate("yes")}</Radio>
              <Radio value="false">{translate("no")}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key="has_pdta"
        dataIndex="has_pdta"
        title={translate("hospitals.fields.has_pdta")}
        render={(value) => (
          <BooleanField
            color={value ? "#52c41a" : "#f5222d"}
            value={value}
            trueIcon={
              <Icons.CheckCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#52c41a"
              />
            }
            falseIcon={
              <Icons.CloseCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#f5222d"
              />
            }
            valueLabelFalse={translate("no")}
            valueLabelTrue={translate("yes")}
          />
        )}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value="true">{translate("yes")}</Radio>
              <Radio value="false">{translate("no")}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column
        key="created_at"
        dataIndex="created_at"
        title={translate("hospitals.fields.created_at")}
        render={(value) => <DateField format="lll" value={value} />}
        sorter
      />,

      <Table.Column
        key="updated_at"
        dataIndex="updated_at"
        title={translate("hospitals.fields.updated_at")}
        render={(value) => <DateField format="lll" value={value} />}
        sorter
      />,
    ],
    [
      tableProps,
      sorter,
      municipalitySelectProps,
      isLoading,
      municipalityData,
      filters,
    ],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(
      (column) => !hiddenColumns.includes(column.props.dataIndex),
    );
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button
              danger
              onClick={deleteSelectedItems}
              disabled={!hasSelected}
              loading={deleteManyIsLoading}
            >
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color="#f1c531">
                {translate("labels.totalNumber", {
                  resource: translate("hospitals.hospitals").toLowerCase(),
                })}
                {tableQueryResult.data?.total || ""}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>
                {translate("table.hideColumns.buttonTitle")}
              </Button>
            </Space>
          ),
        }}
      >
        <Table<IHospital>
          rowSelection={rowSelection}
          {...tableProps}
          size="small"
          scroll={{ y: "60vh", x: 10 * visibleColumns.length + "vw" }}
          pagination={{
            ...tableProps.pagination,
            position: ["topLeft"],
            size: "small",
          }}
          rowKey="id"
        >
          <Table.Column<IHospital>
            title=""
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton
                    title={translate("buttons.show")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <EditButton
                    title={translate("buttons.edit")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <DeleteButton
                    title={translate("buttons.delete")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction="vertical">
          <Typography.Text>
            {translate("table.hideColumns.title")}
          </Typography.Text>
          {defaultColumns.map((column) => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={(e) => handleHideColumn(column.value, e.target.checked)}
            >
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
