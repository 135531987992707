import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ErrorComponent,
  ConfigProvider,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";

import "styles/antd.less";
import { dataProvider } from "@pankod/refine-supabase";

import authProvider from "./authProvider";
import { supabaseClient } from "utility";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { useTranslation } from "react-i18next";

import {
  HospitalCreate,
  HospitalEdit,
  HospitalList,
  HospitalShow,
} from "pages/hospitals";
import {
  RegionCreate,
  RegionEdit,
  RegionList,
  RegionShow,
} from "pages/regions";
import {
  ProvinceCreate,
  ProvinceEdit,
  ProvinceList,
  ProvinceShow,
} from "pages/provinces";
import {
  MunicipalityCreate,
  MunicipalityEdit,
  MunicipalityList,
  MunicipalityShow,
} from "pages/municipalities";
import {
  FaBook,
  FaCommentAlt,
  FaFilter,
  FaHospital,
  FaNetworkWired,
  FaNotesMedical,
  FaQuestion,
} from "react-icons/fa";
import { MdLocationPin, MdMail, MdRateReview, MdStars } from "react-icons/md";
import { GiArchiveResearch } from "react-icons/gi";
import { GoGraph } from "react-icons/go";
import { BiCustomize } from "react-icons/bi";
import { Login } from "pages/login";
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "pages/categories";
import {
  QuestionCreate,
  QuestionEdit,
  QuestionList,
  QuestionShow,
} from "pages/questions";
import { TraitCreate, TraitEdit, TraitList, TraitShow } from "pages/traits";
import {
  DoctorCreate,
  DoctorEdit,
  DoctorList,
  DoctorShow,
} from "pages/doctors";
import {
  VolumeCreate,
  VolumeEdit,
  VolumeList,
  VolumeShow,
} from "pages/volumes";
import { MessageList, MessageShow } from "pages/messages";
import {
  ReviewCreate,
  ReviewEdit,
  ReviewList,
  ReviewShow,
} from "pages/reviews";
import {
  AnswerCreate,
  AnswerEdit,
  AnswerList,
  AnswerShow,
} from "pages/answers";
import {
  CommentCreate,
  CommentEdit,
  CommentList,
  CommentShow,
} from "pages/comments";
import {
  HospitalTraitCreate,
  HospitalTraitList,
  HospitalTraitShow,
} from "pages/hospitalTrait";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { useLocaleStore } from "stores/useLocaleStore";
import {
  DoctorRoleCreate,
  DoctorRoleEdit,
  DoctorRoleShow,
  DoctorRolesList,
} from "pages/doctorsRoles";
import { StageCreate, StageEdit, StageList, StageShow } from "pages/stages";
import {
  ExperimentalStudyCreate,
  ExperimentalStudyEdit,
  ExperimentalStudyList,
  ExperimentalStudyShow,
} from "pages/experimentalStudies";
import {
  HospitalStudyCreate,
  HospitalStudyList,
  HospitalStudyShow,
} from "pages/hospitalStudy";
import { dataProvider as dataProviderV2 } from "utility/data-provider";

dayjs.locale("it");

function App() {
  const { t, i18n } = useTranslation();
  const currentLocale = useLocaleStore((state) => state.locale);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ConfigProvider locale={currentLocale}>
      <Refine
        routerProvider={routerProvider}
        dataProvider={dataProviderV2(supabaseClient)}
        authProvider={authProvider}
        LoginPage={Login}
        Title={Title}
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
        i18nProvider={i18nProvider}
        reactQueryClientConfig={{
          defaultOptions: { queries: { retry: false } },
        }}
        warnWhenUnsavedChanges={true}
        resources={[
          {
            name: "regions",
            list: RegionList,
            show: RegionShow,
            edit: RegionEdit,
            create: RegionCreate,
            canDelete: true,
            icon: <MdLocationPin />,
          },
          {
            name: "provinces",
            list: ProvinceList,
            show: ProvinceShow,
            edit: ProvinceEdit,
            create: ProvinceCreate,
            canDelete: true,
            icon: <MdLocationPin />,
          },
          {
            name: "municipalities",
            list: MunicipalityList,
            show: MunicipalityShow,
            edit: MunicipalityEdit,
            create: MunicipalityCreate,
            canDelete: true,
            icon: <MdLocationPin />,
          },
          {
            name: "hospitals",
            list: HospitalList,
            show: HospitalShow,
            edit: HospitalEdit,
            create: HospitalCreate,
            canDelete: true,
            icon: <FaHospital />,
          },
          {
            name: "experimental_studies_stages",
            list: StageList,
            show: StageShow,
            edit: StageEdit,
            create: StageCreate,
            canDelete: true,
            icon: <GiArchiveResearch />,
          },
          {
            name: "experimental_studies",
            list: ExperimentalStudyList,
            show: ExperimentalStudyShow,
            edit: ExperimentalStudyEdit,
            create: ExperimentalStudyCreate,
            canDelete: true,
            icon: <GiArchiveResearch />,
          },
          {
            name: "hospital_study",
            list: HospitalStudyList,
            show: HospitalStudyShow,
            create: HospitalStudyCreate,
            canDelete: true,
            icon: <GiArchiveResearch />,
          },
          {
            name: "traits",
            list: TraitList,
            show: TraitShow,
            edit: TraitEdit,
            create: TraitCreate,
            canDelete: true,
            icon: <FaFilter />,
          },
          {
            name: "hospital_trait",
            list: HospitalTraitList,
            show: HospitalTraitShow,
            create: HospitalTraitCreate,
            canDelete: true,
            icon: <BiCustomize />,
          },
          {
            name: "categories",
            list: CategoryList,
            show: CategoryShow,
            edit: CategoryEdit,
            create: CategoryCreate,
            canDelete: true,
            icon: <FaBook />,
          },
          {
            name: "questions",
            list: QuestionList,
            show: QuestionShow,
            edit: QuestionEdit,
            create: QuestionCreate,
            canDelete: true,
            icon: <FaQuestion />,
          },
          {
            name: "referring_doctors",
            list: DoctorList,
            show: DoctorShow,
            edit: DoctorEdit,
            create: DoctorCreate,
            canDelete: true,
            icon: <FaNotesMedical />,
          },
          {
            name: "referring_doctors_roles",
            list: DoctorRolesList,
            show: DoctorRoleShow,
            edit: DoctorRoleEdit,
            create: DoctorRoleCreate,
            canDelete: true,
            icon: <FaNetworkWired />,
          },
          {
            name: "hospital_activity_volumes",
            list: VolumeList,
            show: VolumeShow,
            edit: VolumeEdit,
            create: VolumeCreate,
            canDelete: true,
            icon: <GoGraph />,
          },
          {
            name: "reviews",
            list: ReviewList,
            show: ReviewShow,
            edit: ReviewEdit,
            create: ReviewCreate,
            canDelete: true,
            icon: <MdRateReview />,
          },
          {
            name: "answers",
            list: AnswerList,
            show: AnswerShow,
            edit: AnswerEdit,
            create: AnswerCreate,
            canDelete: true,
            icon: <MdStars />,
          },
          {
            name: "comments",
            list: CommentList,
            show: CommentShow,
            edit: CommentEdit,
            create: CommentCreate,
            canDelete: true,
            icon: <FaCommentAlt />,
          },
          {
            name: "messages",
            list: MessageList,
            show: MessageShow,
            canDelete: true,
            icon: <MdMail />,
          },
        ]}
        notificationProvider={notificationProvider}
        catchAll={<ErrorComponent />}
      ></Refine>
    </ConfigProvider>
  );
}

export default App;
