import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Edit, InputNumber } from "@pankod/refine-antd";
import { ICategory } from 'interfaces';

export const CategoryEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<ICategory>();

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('categories.fields.title')} name='title' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={translate('categories.fields.description')} name='description' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={translate('categories.fields.weight')} name='weight' rules={[{ required: true }]}>
          <InputNumber step={0.001} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
