import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Edit } from "@pankod/refine-antd";
import { IRegion } from 'interfaces';

export const RegionEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IRegion>();

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('regions.fields.denomination')} name='denomination' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
