// @ts-nocheck
import type { DataProvider } from "@pankod/refine-core";
import type { SupabaseClient } from "@supabase/supabase-js";
import type { CrudFilter } from "@pankod/refine-core";
import type { CrudOperators } from "@pankod/refine-core";
import type { HttpError } from "@pankod/refine-core";
import type { PostgrestError } from "@supabase/supabase-js";

export const handleError = (error: PostgrestError) => {
  const customError: HttpError = {
    ...error,
    message: error.message,
    statusCode: Number.parseInt(error.code),
  };
  return Promise.reject(customError);
};

export const mapOperator = (operator: CrudOperators) => {
  switch (operator) {
    case "ne":
      return "neq";
    case "nin":
      return "not.in";
    case "contains":
      return "ilike";
    case "ncontains":
      return "not.ilike";
    case "containss":
      return "like";
    case "ncontainss":
      return "not.like";
    case "null":
      return "is";
    case "nnull":
      return "not.is";
    case "ina":
      return "cs";
    case "nina":
      return "not.cs";
    case "between":
    case "nbetween":
      throw Error(`Operator ${operator} is not supported`);
    default:
      return operator;
  }
};

export const generateFilter = (filter: CrudFilter, query: any) => {
  if (filter.value === "null") {
    return query.is(filter.field, null);
  }
  switch (filter.operator) {
    case "eq":
      return query.eq(filter.field, filter.value);
    case "ne":
      return query.neq(filter.field, filter.value);
    case "in":
      return query.in(filter.field, filter.value);
    case "ina":
      return query.contains(filter.field, filter.value);
    case "nina":
      return query.not(
        filter.field,
        "cs",
        `{${filter.value.map((val: any) => `"${val}"`).join(",")}}`,
      );

    case "gt":
      return query.gt(filter.field, filter.value);
    case "gte":
      return query.gte(filter.field, filter.value);
    case "lt":
      return query.lt(filter.field, filter.value);
    case "lte":
      return query.lte(filter.field, filter.value);
    case "contains":
      return query.ilike(filter.field, `%${filter.value}%`);
    case "containss":
      return query.like(filter.field, `%${filter.value}%`);
    case "null":
      return query.is(filter.field, null);
    case "startswith":
      return query.ilike(filter.field, `${filter.value}%`);
    case "endswith":
      return query.ilike(filter.field, `%${filter.value}`);
    case "or": {
      const orSyntax = filter.value
        .map((item) => {
          if (
            item.operator !== "or" &&
            item.operator !== "and" &&
            "field" in item
          ) {
            let value = item.value;

            if (item.operator === "ina" || item.operator === "nina") {
              value = `{${item.value.map((val: any) => `"${val}"`).join(",")}}`;
            }

            return `${item.field}.${mapOperator(item.operator)}.${value}`;
          }
          return;
        })
        .join(",");
      return query.or(orSyntax);
    }

    case "and":
      throw Error("Operator 'and' is not supported");
    default:
      return query.filter(
        filter.field,
        mapOperator(filter.operator),
        filter.value,
      );
  }
};
export const dataProvider = (
  supabaseClient: SupabaseClient<any, any, any>,
): Required<DataProvider> => {
  return {
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
      const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

      const client = meta?.schema
        ? supabaseClient.schema(meta.schema)
        : supabaseClient;

      const query = client.from(resource).select(meta?.select ?? "*", {
        count: meta?.count ?? "exact",
      });

      if (mode === "server") {
        query.range((current - 1) * pageSize, current * pageSize - 1);
      }

      sorters?.map((item) => {
        const [foreignTable, field] = item.field.split(/\.(?=[^.]+$)/);

        if (foreignTable && field) {
          query
            .select(meta?.select ?? `*, ${foreignTable}(${field})`)
            .order(field, {
              ascending: item.order === "asc",
              foreignTable: foreignTable,
            });
        } else {
          query.order(item.field, {
            ascending: item.order === "asc",
          });
        }
      });

      filters?.map((item) => {
        generateFilter(item, query);
      });

      const { data, count, error } = await query;

      if (error) {
        return handleError(error);
      }

      return {
        data: data || [],
        total: count || 0,
      } as any;
    },

    getMany: async ({ resource, ids, meta }) => {
      const client = meta?.schema
        ? supabaseClient.schema(meta.schema)
        : supabaseClient;

      const query = client.from(resource).select(meta?.select ?? "*");

      if (meta?.idColumnName) {
        query.in(meta.idColumnName, ids);
      } else {
        query.in("id", ids);
      }

      const { data, error } = await query;

      if (error) {
        return handleError(error);
      }

      return {
        data: data || [],
      } as any;
    },

    create: async ({ resource, variables, meta }) => {
      const client = meta?.schema
        ? supabaseClient.schema(meta.schema)
        : supabaseClient;

      const query = client.from(resource).insert(variables);

      query.select(meta?.select ?? "*");

      const { data, error } = await query;

      if (error) {
        return handleError(error);
      }

      return {
        data: (data || [])[0] as any,
      };
    },

    createMany: async ({ resource, variables, meta }) => {
      const client = meta?.schema
        ? supabaseClient.schema(meta.schema)
        : supabaseClient;

      const query = client.from(resource).insert(variables);

      query.select(meta?.select ?? "*");

      const { data, error } = await query;

      if (error) {
        return handleError(error);
      }

      return {
        data: data as any,
      };
    },

    update: async ({ resource, id, variables, meta }) => {
      const client = meta?.schema
        ? supabaseClient.schema(meta.schema)
        : supabaseClient;

      const query = client.from(resource).update(variables);

      if (meta?.idColumnName) {
        query.eq(meta.idColumnName, id);
      } else {
        query.match({ id });
      }

      query.select(meta?.select ?? "*");

      const { data, error } = await query;
      if (error) {
        return handleError(error);
      }

      return {
        data: (data || [])[0] as any,
      };
    },

    updateMany: async ({ resource, ids, variables, meta }) => {
      const response = await Promise.all(
        ids.map(async (id) => {
          const client = meta?.schema
            ? supabaseClient.schema(meta.schema)
            : supabaseClient;

          const query = client.from(resource).update(variables);

          if (meta?.idColumnName) {
            query.eq(meta.idColumnName, id);
          } else {
            query.match({ id });
          }

          query.select(meta?.select ?? "*");

          const { data, error } = await query;
          if (error) {
            return handleError(error);
          }

          return (data || [])[0] as any;
        }),
      );

      return {
        data: response,
      };
    },

    getOne: async ({ resource, id, meta }) => {
      const client = meta?.schema
        ? supabaseClient.schema(meta.schema)
        : supabaseClient;

      const query = client.from(resource).select(meta?.select ?? "*");

      if (meta?.idColumnName) {
        query.eq(meta.idColumnName, id);
      } else {
        query.match({ id });
      }

      const { data, error } = await query;
      if (error) {
        return handleError(error);
      }

      return {
        data: (data || [])[0] as any,
      };
    },

    deleteOne: async ({ resource, id, meta }) => {
      const client = meta?.schema
        ? supabaseClient.schema(meta.schema)
        : supabaseClient;

      const query = client.from(resource).delete();

      if (meta?.idColumnName) {
        query.eq(meta.idColumnName, id);
      } else {
        query.match({ id });
      }

      const { data, error } = await query;
      if (error) {
        return handleError(error);
      }

      return {
        data: (data || [])[0] as any,
      };
    },

    deleteMany: async ({ resource, ids, meta }) => {
      const response = await Promise.all(
        ids.map(async (id) => {
          const client = meta?.schema
            ? supabaseClient.schema(meta.schema)
            : supabaseClient;

          const query = client.from(resource).delete();

          if (meta?.idColumnName) {
            query.eq(meta.idColumnName, id);
          } else {
            query.match({ id });
          }

          const { data, error } = await query;
          if (error) {
            return handleError(error);
          }

          return (data || [])[0] as any;
        }),
      );

      return {
        data: response,
      };
    },

    getApiUrl: () => {
      throw Error("Not implemented on refine-supabase data provider.");
    },

    custom: () => {
      throw Error("Not implemented on refine-supabase data provider.");
    },
  };
};
