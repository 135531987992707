import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import {
  Show,
  Typography,
  Icons,
  BooleanField,
  DateField,
} from "@pankod/refine-antd";
import { IHospital, IMunicipality } from "interfaces";

const { Title, Text } = Typography;

export const HospitalShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IHospital>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: municipalityData } = useOne<IMunicipality>({
    resource: "municipalities",
    id: record?.municipality_id || "",
    queryOptions: {
      enabled: !!record?.municipality_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate("hospitals.fields.id")}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate("hospitals.fields.facility_code")}</Title>
      <Text>{record?.facility_code}</Text>

      <Title level={5}>{translate("hospitals.fields.denomination")}</Title>
      <Text>{record?.denomination}</Text>

      <Title level={5}>
        {translate("hospitals.fields.municipality.title")}
      </Title>
      <Text>{municipalityData?.data.denomination}</Text>

      <Title level={5}>{translate("hospitals.fields.address")}</Title>
      <Text>{record?.address || translate("notAvailable")}</Text>

      <Title level={5}>{translate("hospitals.fields.zip_code")}</Title>
      <Text>{record?.zip_code || translate("notAvailable")}</Text>

      <Title level={5}>{translate("hospitals.fields.website")}</Title>
      <Text>{record?.website || translate("notAvailable")}</Text>

      <Title level={5}>{translate("hospitals.fields.email")}</Title>
      <Text>{record?.email || translate("notAvailable")}</Text>

      <Title level={5}>{translate("hospitals.fields.phone_number")}</Title>
      <Text>{record?.phone_number || translate("notAvailable")}</Text>

      <Title level={5}>{translate("hospitals.fields.infos")}</Title>
      <Text>{record?.infos || translate("notAvailable")}</Text>

      <Title level={5}>{translate("hospitals.fields.has_agreement")}</Title>
      <BooleanField
        color={record?.has_agreement ? "#52c41a" : "#f5222d"}
        value={record?.has_agreement}
        trueIcon={
          <Icons.CheckCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#52c41a"
          />
        }
        falseIcon={
          <Icons.CloseCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#f5222d"
          />
        }
        valueLabelFalse={translate("no")}
        valueLabelTrue={translate("yes")}
      />

      <Title level={5} style={{ marginTop: 16 }}>
        {translate("hospitals.fields.is_deprecated")}
      </Title>
      <BooleanField
        color={record?.is_deprecated ? "#f5222d" : "#52c41a"}
        value={record?.is_deprecated}
        trueIcon={
          <Icons.CheckCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#f5222d"
          />
        }
        falseIcon={
          <Icons.CloseCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#52c41a"
          />
        }
        valueLabelFalse={translate("no")}
        valueLabelTrue={translate("yes")}
      />

      <Title level={5} style={{ marginTop: 16 }}>
        {translate("hospitals.fields.does_research")}
      </Title>
      <BooleanField
        color={record?.does_research ? "#52c41a" : "#f5222d"}
        value={record?.does_research}
        trueIcon={
          <Icons.CheckCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#52c41a"
          />
        }
        falseIcon={
          <Icons.CloseCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#f5222d"
          />
        }
        valueLabelFalse={translate("no")}
        valueLabelTrue={translate("yes")}
      />
      <Title level={5} style={{ marginTop: 16 }}>
        {translate("hospitals.fields.is_accredited")}
      </Title>
      <BooleanField
        color={record?.is_accredited ? "#52c41a" : "#f5222d"}
        value={record?.is_accredited}
        trueIcon={
          <Icons.CheckCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#52c41a"
          />
        }
        falseIcon={
          <Icons.CloseCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#f5222d"
          />
        }
        valueLabelFalse={translate("no")}
        valueLabelTrue={translate("yes")}
      />
      <Title level={5} style={{ marginTop: 16 }}>
        {translate("hospitals.fields.has_multidisciplinary_team")}
      </Title>
      <BooleanField
        color={record?.has_multidisciplinary_team ? "#52c41a" : "#f5222d"}
        value={record?.has_multidisciplinary_team}
        trueIcon={
          <Icons.CheckCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#52c41a"
          />
        }
        falseIcon={
          <Icons.CloseCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#f5222d"
          />
        }
        valueLabelFalse={translate("no")}
        valueLabelTrue={translate("yes")}
      />
      <Title level={5} style={{ marginTop: 16 }}>
        {translate("hospitals.fields.has_pdta")}
      </Title>
      <BooleanField
        color={record?.has_pdta ? "#52c41a" : "#f5222d"}
        value={record?.has_pdta}
        trueIcon={
          <Icons.CheckCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#52c41a"
          />
        }
        falseIcon={
          <Icons.CloseCircleTwoTone
            style={{ fontSize: 18 }}
            twoToneColor="#f5222d"
          />
        }
        valueLabelFalse={translate("no")}
        valueLabelTrue={translate("yes")}
      />
      <Title style={{ marginTop: 16 }} level={5}>
        {translate("hospitals.fields.created_at")}
      </Title>
      <DateField format="lll" value={record?.created_at} />

      <Title level={5}>{translate("hospitals.fields.updated_at")}</Title>
      <DateField format="lll" value={record?.updated_at} />
    </Show>
  );
};
