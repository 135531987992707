import React from 'react';
import { useTranslate, useDeleteMany, useMany } from "@pankod/refine-core";

import {
  useTable,
  List,
  Table,
  DateField,
  useModal,
  Button,
  Space,
  CreateButton,
  ShowButton,
  EditButton,
  DeleteButton,
  Modal,
  Typography,
  Checkbox,
  FilterDropdown,
  getDefaultFilter,
  Input,
  getDefaultSortOrder,
  useSelect,
  TextField,
  Select,
  Tag,
} from "@pankod/refine-antd";

import { IMunicipality, IProvince } from 'interfaces';

export const MunicipalityList = () => {
  const translate = useTranslate();
  const { tableProps, sorter, filters, tableQueryResult } = useTable<IMunicipality>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc',
      },
    ],
    initialFilter: [
      {
        field: 'denomination',
        operator: 'contains',
        value: undefined,
      },
    ],
  });

  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate('municipalities.fields.id'),
        value: 'id',
      },
      {
        label: translate('municipalities.fields.denomination'),
        value: 'denomination',
      },
      {
        label: translate('municipalities.fields.province.title'),
        value: 'province_id',
      },
      {
        label: translate('municipalities.fields.created_at'),
        value: 'created_at',
      },
      {
        label: translate('municipalities.fields.updated_at'),
        value: 'updated_at',
      },
    ],
    [],
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const provincesID = tableProps?.dataSource?.map(item => item.province_id) ?? [];
  const { data: provinceData, isLoading } = useMany<IProvince>({
    resource: 'provinces',
    ids: provincesID,
    queryOptions: {
      enabled: provincesID.length > 0,
    },
  });
  const { selectProps: provinceSelectProps } = useSelect<IProvince>({
    resource: 'provinces',
    optionLabel: 'denomination',
    optionValue: 'id',
  });
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(['created_at', 'updated_at', 'id']);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { mutate, isSuccess, isLoading: deleteManyIsLoading } = useDeleteMany<IMunicipality>();
  const deleteSelectedItems = () => {
    mutate({
      resource: 'municipalities',
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked) setHiddenColumns(prev => prev.filter(col => col !== value));
    else setHiddenColumns(prev => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column<IMunicipality>
        key='id'
        dataIndex='id'
        title={translate('municipalities.fields.id')}
        sorter
        defaultSortOrder={getDefaultSortOrder('id', sorter)}
      />,
      <Table.Column<IMunicipality>
        key='denomination'
        dataIndex='denomination'
        title={translate('municipalities.fields.denomination')}
        filterDropdown={props => (
          <FilterDropdown {...props}>
            <Input placeholder={translate('labels.search')} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter('denomination', filters)}
      />,
      <Table.Column
        key='province_id'
        dataIndex='province_id'
        title={translate('municipalities.fields.province.title')}
        render={(value: string) => {
          if (isLoading) {
            return <TextField value={translate('loading')} />;
          }
          return <TextField value={provinceData?.data.find((item: IProvince) => item.id === value)?.denomination} />;
        }}
        filterDropdown={props => (
          <FilterDropdown {...props}>
            <Select
              mode='multiple'
              style={{ minWidth: 200 }}
              placeholder={translate('municipalities.fields.province.filters.placeholder')}
              {...provinceSelectProps}
            />
          </FilterDropdown>
        )}
      />,
      <Table.Column<IMunicipality>
        key='created_at'
        dataIndex='created_at'
        title={translate('municipalities.fields.created_at')}
        render={value => <DateField format='LLL' value={value} />}
        sorter
      />,
      <Table.Column<IMunicipality>
        key='updated_at'
        dataIndex='updated_at'
        title={translate('municipalities.fields.updated_at')}
        render={value => <DateField format='LLL' value={value} />}
        sorter
      />,
    ],
    [tableProps, filters, sorter, provinceData, provinceSelectProps, isLoading],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(column => !hiddenColumns.includes(column.props.dataIndex));
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button danger onClick={deleteSelectedItems} disabled={!hasSelected} loading={deleteManyIsLoading}>
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color='#f1c531'>
                {translate('labels.totalNumber', {
                  resource: translate('municipalities.municipalities').toLowerCase(),
                })}
                {tableQueryResult.data?.total || ''}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>{translate('table.hideColumns.buttonTitle')}</Button>
            </Space>
          ),
        }}>
        <Table<IMunicipality>
          rowSelection={rowSelection}
          {...tableProps}
          size='small'
          scroll={{ y: '60vh', x: 10 * visibleColumns.length + 'vw' }}
          pagination={{ ...tableProps.pagination, position: ['topLeft'], size: 'small' }}
          rowKey='id'>
          <Table.Column<IMunicipality>
            title=''
            dataIndex='actions'
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton title={translate('buttons.show')} size='small' recordItemId={record.id} hideText />
                  <EditButton title={translate('buttons.edit')} size='small' recordItemId={record.id} hideText />
                  <DeleteButton title={translate('buttons.delete')} size='small' recordItemId={record.id} hideText />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction='vertical'>
          <Typography.Text>{translate('table.hideColumns.title')}</Typography.Text>
          {defaultColumns.map(column => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={e => handleHideColumn(column.value, e.target.checked)}>
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
