import { useTranslate } from "@pankod/refine-core";
import {
  useForm,
  Form,
  Input,
  Radio,
  Select,
  Edit,
  useSelect,
} from "@pankod/refine-antd";
import { IHospital } from "interfaces";

export const HospitalEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IHospital>();

  const { selectProps: municipalitySelectProps } = useSelect<IHospital>({
    resource: "municipalities",
    defaultValue: queryResult?.data?.data?.municipality_id,
    optionLabel: "denomination",
    optionValue: "id",
  });

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate("hospitals.fields.facility_code")}
          name="facility_code"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.denomination")}
          name="denomination"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.municipality.title")}
          name="municipality_id"
          rules={[{ required: true }]}
        >
          <Select {...municipalitySelectProps} />
        </Form.Item>
        <Form.Item label={translate("hospitals.fields.address")} name="address">
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.zip_code")}
          name="zip_code"
        >
          <Input />
        </Form.Item>
        <Form.Item label={translate("hospitals.fields.website")} name="website">
          <Input />
        </Form.Item>
        <Form.Item label={translate("hospitals.fields.email")} name="email">
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.phone_number")}
          name="phone_number"
        >
          <Input />
        </Form.Item>
        <Form.Item label={translate("hospitals.fields.infos")} name="infos">
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.has_agreement")}
          name="has_agreement"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.is_deprecated")}
          name="is_deprecated"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.does_research")}
          name="does_research"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.is_accredited")}
          name="is_accredited"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.has_multidisciplinary_team")}
          name="has_multidisciplinary_team"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={translate("hospitals.fields.has_pdta")}
          name="has_pdta"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Edit>
  );
};
