import create from 'zustand';
import itLocale from 'antd/lib/locale/it_IT';

type Locale = typeof itLocale;

type LocaleStore = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};

export const useLocaleStore = create<LocaleStore>(set => ({
  locale: itLocale,
  setLocale: locale => set({ locale }),
}));
