import { useShow, useOne, useTranslate } from '@pankod/refine-core';
import { Show, Typography, Icons, BooleanField, DateField } from '@pankod/refine-antd';
import { IDoctor, IDoctorRole, IHospital } from 'interfaces';

const { Title, Text } = Typography;

export const DoctorShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IDoctor>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: hospitalData } = useOne<IHospital>({
    resource: 'hospitals',
    id: record?.hospital_id || '',
    queryOptions: {
      enabled: !!record?.hospital_id,
    },
  });

  const { data: roleData } = useOne<IDoctorRole>({
    resource: 'referring_doctors_roles',
    id: record?.role_id || '',
    queryOptions: {
      enabled: !!record?.role_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('referring_doctors.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('referring_doctors.fields.first_name')}</Title>
      <Text>{record?.first_name || translate('notAvailable')}</Text>

      <Title level={5}>{translate('referring_doctors.fields.last_name')}</Title>
      <Text>{record?.last_name}</Text>

      <Title level={5}>{translate('referring_doctors.fields.field')}</Title>
      <Text>{record?.field || translate('notAvailable')}</Text>

      <Title level={5}>{translate('referring_doctors.fields.role.title')}</Title>
      <Text>{roleData?.data.role_name || translate('notAvailable')}</Text>

      <Title level={5}>{translate('referring_doctors.fields.interview_url')}</Title>
      <Text>{record?.interview_url || translate('notAvailable')}</Text>

      <Title level={5}>{translate('referring_doctors.fields.hospital.title')}</Title>
      <Text>{hospitalData?.data.denomination}</Text>

      <Title level={5}>{translate('referring_doctors.fields.causes_deprecation')}</Title>
      <BooleanField
        color={record?.causes_deprecation ? '#f5222d' : '#52c41a'}
        value={record?.causes_deprecation}
        trueIcon={<Icons.CheckCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#f5222d' />}
        falseIcon={<Icons.CloseCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#52c41a' />}
        valueLabelFalse={translate('no')}
        valueLabelTrue={translate('yes')}
      />

      <Title style={{ marginTop: 16 }} level={5}>
        {translate('referring_doctors.fields.created_at')}
      </Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('referring_doctors.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
