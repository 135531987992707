import { Spin } from "@pankod/refine-antd";

export const SuspenseFallback = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#2E3192',
        color: '#F1C531',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Spin size='large' />
    </div>
  );
};
