import React from 'react';
import { useMany, useTranslate, useGetLocale, useDeleteMany } from '@pankod/refine-core';

import {
  Input,
  useTable,
  List,
  Table,
  DateField,
  Select,
  useSelect,
  TextField,
  FilterDropdown,
  ShowButton,
  Space,
  EditButton,
  DeleteButton,
  Radio,
  getDefaultSortOrder,
  BooleanField,
  Button,
  CreateButton,
  useModal,
  Modal,
  Checkbox,
  Typography,
  Icons,
  getDefaultFilter,
  Tag,
} from '@pankod/refine-antd';

import { IDoctor, IDoctorRole } from 'interfaces';

export const DoctorRolesList = () => {
  const translate = useTranslate();
  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate('referring_doctors_roles.fields.id'),
        value: 'id',
      },
      {
        label: translate('referring_doctors_roles.fields.role_name'),
        value: 'role_name',
      },
      {
        label: translate('referring_doctors_roles.fields.sorting_order'),
        value: 'sorting_order',
      },
      {
        label: translate('referring_doctors_roles.fields.created_at'),
        value: 'created_at',
      },
      {
        label: translate('referring_doctors_roles.fields.updated_at'),
        value: 'updated_at',
      },
    ],
    []
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(['created_at', 'updated_at', 'id']);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { tableProps, filters, sorter, tableQueryResult } = useTable<IDoctorRole>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc',
      },
      {
        field: 'sorting_order',
        order: 'asc',
      },
    ],
    initialFilter: [
      {
        field: 'role_name',
        operator: 'contains',
        value: undefined,
      },
    ],
  });
  const { mutate, isSuccess, isLoading: deleteManyIsLoading } = useDeleteMany<IDoctorRole>();
  const deleteSelectedItems = () => {
    mutate({
      resource: 'referring_doctors_roles',
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked) setHiddenColumns((prev) => prev.filter((col) => col !== value));
    else setHiddenColumns((prev) => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column
        key='id'
        dataIndex='id'
        title={translate('referring_doctors_roles.fields.id')}
        sorter
        defaultSortOrder={getDefaultSortOrder('id', sorter)}
      />,
      <Table.Column
        key='role_name'
        dataIndex='role_name'
        title={translate('referring_doctors_roles.fields.role_name')}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder={translate('labels.search')} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter('role_name', filters)}
      />,
      <Table.Column
        key='sorting_order'
        dataIndex='sorting_order'
        title={translate('referring_doctors_roles.fields.sorting_order')}
        defaultSortOrder={getDefaultSortOrder('sorting_order', sorter)}
      />,
      <Table.Column
        key='created_at'
        dataIndex='created_at'
        title={translate('referring_doctors_roles.fields.created_at')}
        render={(value) => <DateField format='lll' value={value} />}
        sorter
      />,

      <Table.Column
        key='updated_at'
        dataIndex='updated_at'
        title={translate('referring_doctors_roles.fields.updated_at')}
        render={(value) => <DateField format='lll' value={value} />}
        sorter
      />,
    ],
    [tableProps, sorter, filters, translate]
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter((column) => !hiddenColumns.includes(column.props.dataIndex));
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button danger onClick={deleteSelectedItems} disabled={!hasSelected} loading={deleteManyIsLoading}>
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color='#f1c531'>
                {translate('labels.totalNumber', {
                  resource: translate('referring_doctors_roles.referring_doctors_roles').toLowerCase(),
                })}
                {tableQueryResult.data?.total || ''}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>{translate('table.hideColumns.buttonTitle')}</Button>
            </Space>
          ),
        }}
      >
        <Table<IDoctorRole>
          rowSelection={rowSelection}
          {...tableProps}
          size='small'
          scroll={{ y: '60vh', x: 10 * visibleColumns.length + 'vw' }}
          pagination={{ ...tableProps.pagination, position: ['topLeft'], size: 'small' }}
          rowKey='id'
        >
          <Table.Column<IDoctor>
            title=''
            dataIndex='actions'
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton title={translate('buttons.show')} size='small' recordItemId={record.id} hideText />
                  <EditButton title={translate('buttons.edit')} size='small' recordItemId={record.id} hideText />
                  <DeleteButton title={translate('buttons.delete')} size='small' recordItemId={record.id} hideText />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction='vertical'>
          <Typography.Text>{translate('table.hideColumns.title')}</Typography.Text>
          {defaultColumns.map((column) => (
            <Checkbox
              key={column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={(e) => handleHideColumn(column.value, e.target.checked)}
            >
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
