import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField } from "@pankod/refine-antd";
import { IRegion } from 'interfaces';

const { Title, Text } = Typography;

export const RegionShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IRegion>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('regions.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('regions.fields.denomination')}</Title>
      <Text>{record?.denomination}</Text>

      <Title level={5}>{translate('regions.fields.created_at')}</Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('regions.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
