import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Icons, BooleanField, DateField } from "@pankod/refine-antd";
import { IReview, IHospital } from 'interfaces';

const { Title, Text } = Typography;

export const ReviewShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IReview>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: hospitalData } = useOne<IHospital>({
    resource: 'hospitals',
    id: record?.hospital_id || '',
    queryOptions: {
      enabled: !!record?.hospital_id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate('reviews.fields.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate('reviews.fields.hospital.title')}</Title>
      <Text>{hospitalData?.data.denomination}</Text>

      <Title level={5}>{translate('reviews.fields.is_deprecated')}</Title>
      <BooleanField
        color={record?.is_deprecated ? '#f5222d' : '#52c41a'}
        value={record?.is_deprecated}
        trueIcon={<Icons.CheckCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#f5222d' />}
        falseIcon={<Icons.CloseCircleTwoTone style={{ fontSize: 18 }} twoToneColor='#52c41a' />}
        valueLabelFalse={translate('no')}
        valueLabelTrue={translate('yes')}
      />

      <Title style={{ marginTop: 16 }} level={5}>
        {translate('reviews.fields.created_at')}
      </Title>
      <DateField format='lll' value={record?.created_at} />

      <Title level={5}>{translate('reviews.fields.updated_at')}</Title>
      <DateField format='lll' value={record?.updated_at} />
    </Show>
  );
};
