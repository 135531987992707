import { useTranslate } from '@pankod/refine-core';

import { Create, Form, Input, InputNumber, useForm } from '@pankod/refine-antd';

import { IDoctorRole } from 'interfaces';

export const DoctorRoleCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IDoctorRole>();

  return (
    <Create isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item
          label={translate('referring_doctors_roles.fields.role_name')}
          name='role_name'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('referring_doctors_roles.fields.sorting_order')}
          name='sorting_order'
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Create>
  );
};
