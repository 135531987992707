import { useTranslate } from '@pankod/refine-core';

import { Create, Form, Input, Radio, Select, useForm, useSelect } from '@pankod/refine-antd';

import { IDoctor, IDoctorRole } from 'interfaces';

export const DoctorCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IDoctor>();
  const { selectProps: hospitalSelectProps } = useSelect<IDoctor>({
    resource: 'hospitals',
    optionLabel: 'denomination',
    optionValue: 'id',
  });

  const { selectProps: roleSelectProps } = useSelect<IDoctorRole>({
    resource: 'referring_doctors_roles',
    defaultValue: queryResult?.data?.data?.role_id ?? '',
    optionLabel: 'role_name',
    optionValue: 'id',
  });

  return (
    <Create isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <Form.Item label={translate('referring_doctors.fields.first_name')} name='first_name'>
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('referring_doctors.fields.last_name')}
          name='last_name'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('referring_doctors.fields.hospital.title')}
          name='hospital_id'
          rules={[{ required: true }]}
        >
          <Select {...hospitalSelectProps} />
        </Form.Item>
        <Form.Item label={translate('referring_doctors.fields.field')} name='field' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={translate('referring_doctors.fields.role.title')} name='role_id'>
          <Select {...roleSelectProps} options={[{ label: '', value: null }, ...(roleSelectProps.options ?? [])]} />
        </Form.Item>
        <Form.Item label={translate('referring_doctors.fields.interview_url')} name='interview_url'>
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('referring_doctors.fields.causes_deprecation')}
          name='causes_deprecation'
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Sì</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Create>
  );
};
