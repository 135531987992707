import { useTranslate } from "@pankod/refine-core";
import { useForm, Form, Input, Edit } from "@pankod/refine-antd";
import { IExperimentalStudyStage } from "interfaces";

export const StageEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } =
    useForm<IExperimentalStudyStage>();

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate("experimental_studies_stages.fields.name")}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
