import React from "react";
import { useTranslate, useDeleteMany } from "@pankod/refine-core";

import {
  Radio,
  useTable,
  List,
  Table,
  DateField,
  useModal,
  Button,
  Space,
  CreateButton,
  ShowButton,
  EditButton,
  DeleteButton,
  Modal,
  Typography,
  Checkbox,
  FilterDropdown,
  getDefaultFilter,
  Input,
  getDefaultSortOrder,
  BooleanField,
  Icons,
  Tag,
} from "@pankod/refine-antd";

import { ITrait } from "interfaces";

export const TraitList = () => {
  const translate = useTranslate();
  const { tableProps, sorter, filters, tableQueryResult } = useTable<ITrait>({
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    initialFilter: [
      {
        field: "kind",
        operator: "contains",
        value: undefined,
      },
      {
        field: "definition",
        operator: "contains",
        value: undefined,
      },
    ],
  });

  const defaultColumns = React.useMemo(
    () => [
      {
        label: translate("traits.fields.id"),
        value: "id",
      },
      {
        label: translate("traits.fields.kind"),
        value: "kind",
      },
      {
        label: translate("traits.fields.definition"),
        value: "definition",
      },
      {
        label: translate("traits.fields.description"),
        value: "description",
      },
      {
        label: translate("traits.fields.causes_deprecation"),
        value: "causes_deprecation",
      },
      {
        label: translate("traits.fields.created_at"),
        value: "created_at",
      },
      {
        label: translate("traits.fields.updated_at"),
        value: "updated_at",
      },
    ],
    [],
  );
  const { modalProps: columnsModalProps, show: columnsShow } = useModal();
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([
    "created_at",
    "updated_at",
    "id",
    "description",
  ]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const {
    mutate,
    isSuccess,
    isLoading: deleteManyIsLoading,
  } = useDeleteMany<ITrait>();
  const deleteSelectedItems = () => {
    mutate({
      resource: "traits",
      ids: selectedRowKeys.map(String),
    });
  };
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleHideColumn = (value: string, isChecked: boolean) => {
    if (isChecked)
      setHiddenColumns((prev) => prev.filter((col) => col !== value));
    else setHiddenColumns((prev) => [...prev, value]);
  };
  const columns = React.useMemo(
    () => [
      <Table.Column<ITrait>
        key="id"
        dataIndex="id"
        title={translate("traits.fields.id")}
        sorter
        defaultSortOrder={getDefaultSortOrder("id", sorter)}
      />,
      <Table.Column<ITrait>
        key="kind"
        dataIndex="kind"
        title={translate("traits.fields.kind")}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder={translate("labels.search")} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter("kind", filters)}
      />,
      <Table.Column<ITrait>
        key="definition"
        dataIndex="definition"
        title={translate("traits.fields.definition")}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder={translate("labels.search")} />
          </FilterDropdown>
        )}
        defaultFilteredValue={getDefaultFilter("definition", filters)}
      />,
      <Table.Column<ITrait>
        key="description"
        dataIndex="description"
        title={translate("traits.fields.description")}
      />,
      <Table.Column<ITrait>
        key="causes_deprecation"
        dataIndex="causes_deprecation"
        title={translate("traits.fields.causes_deprecation")}
        render={(value) => (
          <BooleanField
            color={value ? "#f5222d" : "#52c41a"}
            value={value}
            trueIcon={
              <Icons.CheckCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#f5222d"
              />
            }
            falseIcon={
              <Icons.CloseCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor="#52c41a"
              />
            }
            valueLabelFalse={translate("no")}
            valueLabelTrue={translate("yes")}
          />
        )}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Radio.Group>
              <Radio value="true">{translate("yes")}</Radio>
              <Radio value="false">{translate("no")}</Radio>
            </Radio.Group>
          </FilterDropdown>
        )}
      />,
      <Table.Column<ITrait>
        key="created_at"
        dataIndex="created_at"
        title={translate("traits.fields.created_at")}
        render={(value) => <DateField format="LLL" value={value} />}
        sorter
      />,
      <Table.Column<ITrait>
        key="updated_at"
        dataIndex="updated_at"
        title={translate("traits.fields.updated_at")}
        render={(value) => <DateField format="LLL" value={value} />}
        sorter
      />,
    ],
    [tableProps, filters, sorter],
  );
  const visibleColumns = React.useMemo(() => {
    return columns.filter(
      (column) => !hiddenColumns.includes(column.props.dataIndex),
    );
  }, [hiddenColumns, columns]);

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedRowKeys([]);
    }
  }, [isSuccess]);

  return (
    <>
      <List
        pageHeaderProps={{
          subTitle: hasSelected && (
            <Button
              danger
              onClick={deleteSelectedItems}
              disabled={!hasSelected}
              loading={deleteManyIsLoading}
            >
              {`Elimina ${selectedRowKeys.length} oggetti selezionati`}
            </Button>
          ),
          extra: (
            <Space>
              <Tag color="#f1c531">
                {translate("labels.totalNumber", {
                  resource: translate("traits.traits").toLowerCase(),
                })}
                {tableQueryResult.data?.total || ""}
              </Tag>
              <CreateButton />
              <Button onClick={columnsShow}>
                {translate("table.hideColumns.buttonTitle")}
              </Button>
            </Space>
          ),
        }}
      >
        <Table<ITrait>
          rowSelection={rowSelection}
          {...tableProps}
          size="small"
          scroll={{ y: "60vh", x: 10 * visibleColumns.length + "vw" }}
          pagination={{
            ...tableProps.pagination,
            position: ["topLeft"],
            size: "small",
          }}
          rowKey="id"
        >
          <Table.Column<ITrait>
            title=""
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton
                    title={translate("buttons.show")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <EditButton
                    title={translate("buttons.edit")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <DeleteButton
                    title={translate("buttons.delete")}
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
          {visibleColumns}
        </Table>
      </List>
      <Modal footer={null} {...columnsModalProps}>
        <Space direction="vertical">
          <Typography.Text>
            {translate("table.hideColumns.title")}
          </Typography.Text>
          {defaultColumns.map((column) => (
            <Checkbox
              key={column.label + column.value}
              checked={!hiddenColumns.includes(column.value)}
              onChange={(e) => handleHideColumn(column.value, e.target.checked)}
            >
              {column.label}
            </Checkbox>
          ))}
        </Space>
      </Modal>
    </>
  );
};
